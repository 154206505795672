import { Map } from "immutable";
import moment from 'moment';

/**
 * Created by ebondarev
 */
export default class Types {

    static TARGET_MOBILE = 'mobile';
    static TARGET_DESKTOP = 'desktop';
    static TARGET_CLASSIC_MOBILE = 'classic-mobile';
    static TARGET_CLASSIC_DESKTOP = 'classic-desktop';
    static TARGET_CUSTOM2_MOBILE = 'custom2-mobile';
    static TARGET_CUSTOM2_DESKTOP = 'custom2-desktop';
    static TARGET_VERSION6_MOBILE = 'version6-mobile';
    static TARGET_VERSION6_DESKTOP = 'version6-desktop';

    static THEMES = {
        dark: 'dark',
        light: 'light'
    }

    static THEME_COLORS = {
        default: 'default',
        dark: 'dark',
        darkYellow: 'dark-yellow',
        light: 'light',
    };

    static EVENT_TYPE_CODE = {
        STOP_OUT: 101,
        MARGIN_CALL: 104,
        POSITION_CLOSED: 4,
    }

    static FIELD_BALANCE = 'Balance';
    static FIELD_REG_DATE = 'RegDate';

    static TRADE_MODE_EXCHANGE = 'simple_exchange';
    static TRADE_MODE_ADVANCED = 'trade';

    static LANGUAGE_EN = 'en';
    static LANGUAGE_RU = 'ru';
    static LANGUAGE_ES = 'es';
    static LANGUAGE_CN = 'cn';
    static LANGUAGE_FR = 'fr';
    static LANGUAGE_PT = 'pt';
    static LANGUAGE_DE = 'de';
    static LANGUAGE_PL = 'pl';
    static LANGUAGE_TR = 'tr';
    static LANGUAGE_HI = 'hi';

    static LANGUAGES = {
        [Types.LANGUAGE_EN]: 'English',
        [Types.LANGUAGE_RU]: 'Русский',
        [Types.LANGUAGE_ES]: 'Spanish',
        [Types.LANGUAGE_CN]: '简体中文',
        [Types.LANGUAGE_FR]: 'Français',
        [Types.LANGUAGE_PT]: 'Portugal',
        [Types.LANGUAGE_DE]: 'Deutsch',
        [Types.LANGUAGE_PL]: 'Polskie',
        [Types.LANGUAGE_TR]: 'Turkish',
        [Types.LANGUAGE_HI]: 'हिन्दी',
    };

    static INSTRUMENT_TRADE_MODE_MARGIN = 0;
    static INSTRUMENT_TRADE_MODE_EXCHANGE = 2;

    static INSTRUMENT_TRADE_MODE_NAME = {
        [Types.INSTRUMENT_TRADE_MODE_MARGIN]: 'Marginal',
        [Types.INSTRUMENT_TRADE_MODE_EXCHANGE]: 'Exchange',
    };

    static CHART_THEME_WHITE = 'white'; // default
    static CHART_THEME_BLACK = 'black';

    static ACCOUNT_DEALER_NAME_REAL = 'REAL';
    static ACCOUNT_DEALER_NAME_DEMO = 'DEMO';

    static ACCOUNT_TYPE_DEMO = 'demo';
    static ACCOUNT_TYPE_REAL = 'real';
    static ACCOUNT_TYPE_PAMM = 'pamm';
    static ACCOUNT_TYPE_MINING = 'mining';
    static ACCOUNT_TYPE_MPF = 'mpf';
    static ACCOUNT_TYPE_FAKE = 'fake';
    static ACCOUNT_TYPE_PROFIT = 'profit';
    static ACCOUNT_TYPE_PAYMENT = 'payment';
    static ACCOUNT_TYPE_AFFILIATE = 'affiliate';

    static ACCOUNT_TRADE_MODE_DEFAULT = 0;
    static ACCOUNT_TRADE_MODE_MINING = 1;
    static ACCOUNT_TRADE_MODE_EXCHAGE = 2;
    static ACCOUNT_TRADE_MODE_INVESTOR = 3;

    static ACCOUNT_TYPE_ID_REFERRAL = 6;
    static ACCOUNT_TYPE_ID_INVESTOR = 8;

    static ACCOUNT_MODEL_FIXED = 'Fixed';

    static TICKS_TO_UNIX_EPOCH = 621355968000000000;

    static CONTRACT_SIZE_BASIC = 'BASIC';
    static CONTRACT_SIZE_LOTS = 'LOTS';

    static POINT_IN_PIPS = 10;

    static SORT_ASC = 'ASC';
    static SORT_DESC = 'DESC';

    static FAVORITED_INDICATORS_STORE_NAME = 'favorited_indicators';
    static INDICATOR_TEMPLATES_STORE_NAME = 'indicator_templates';
    static JUST_REGISTERED_STORE_NAME = 'just_registered';
    static WITHDRAW_METHODS_STORE_NAME = 'withdraw_methods';

    static REJECT_REGISTRATION_USER_EXISTS = 1102;
    static REJECT_REGISTRATION_PHONE_EXISTS = 1105;
    static REJECT_REGISTRATION_VERIFYEMAIL = 1108;
    static REJECT_REGISTRATION_SERVER_MAINTENANCE = 1811;
    static REGISTRATION_TIME_LIMIT_FIRST = 60 ; // 1 min
    static REGISTRATION_TIME_LIMIT_SECOND = 60 * 5; // 5 min
    static REJECT_REGISTRATION_PHONE_WRONG = "PhoneNumber is WRONG";

    static SCROLL_PARENT = "scrollParent"
    static SIGN_UP_FROM_EMAIL_ID = "emailInputWrapper";
    static SIGN_UP_FROM_COUNTRY_ID = "countrySelectorWrapper";
    static SIGN_UP_FROM_PHONE_ID = "phoneInputWrapper";

    static DEFAULT_DATA_TIME = '0001-01-01T00:00:00';
    static DEFAULT_BIRTH_DATE = '0001-01-01T00:00:00';
    static TWO_FA_CODE_LENGTH = 6;
    static ANTI_PHISHING_CODE_LENGTH = 6;

    static VERIFICATION_METHOD = {
        DISABLED: 0,
        GOOGLE: 1,
        EMAIL: 2,
    }

    static INTERCHANGE_INSTRUMENT_TYPE_NAME = 'Interchange';
    static INTERCHANGE_FIAT_CURRENCIES = {
        'USD': 'USD',
        'EUR': 'EUR',
    };
    static INTERCHANGE_PRECISIONS = {
        fiat: 2,
        crypto: 4,
    };
    static INTERCHANGE_CROSS_RATE_PRECISIONS = {
        fiat: 0,
        crypto: 5,
    };
    static INTERCHANGE_MIN_SUM = {
        sum: 10,
        currency :'USD',
    };
    static INTERCHANGE_INCORRECT_ID_SUBSTRING = 'Incorrect interchange Id';
    static INTERCHANGE_PRECISE_ERROR_CODES = {
        '1803': 'Incorrect code',
        'Incorrect interchange Id': 'The code is invalid',
    };
    static INTERCHANGE_COMMON_ERROR_TEXT = 'Error';

    static ERROR_WITHDRAWAL_ATTEMPT_LIMIT = 'WithdrawalAttemptLimit';
    static ERROR_WITHDRAW_NOT_ALLOWED = 'WithdrawNotAllowed';

    // http codes
    static HTTP_CODE_NOCONTENT = 204;
    static HTTP_CODE_UNAUTHORIZED = 401;
    static HTTP_CODE_FORBIDDEN = 403;
    static HTTP_CODE_TOO_MANY_REQUESTS = 429;

    // wss RejectLogin codes
    static REJECT_LOGIN_2FA_INCORRECT_CODE = 1803;
    static REJECT_LOGIN_2FA_INCORRECT_GOOGLE_CODE = 1816;
    static REJECT_LOGIN_2FA_INCORRECT_EMAIL_CODE = 1815;
    static REJECT_LOGIN_2FA_NOT_ENABLED = 1804;
    static REJECT_LOGIN_2FA_ALREADY_ENABLED = 1805;
    static REJECT_LOGIN_2FA_INCORRECT_TOKEN = 1807;
    static REJECT_LOGIN_NOT_FOUND = 2104;
    static REJECT_LOGIN_VERIFYPHONE = 1107;
    static REJECT_LOGIN_VERIFYEMAIL = 1108;
    static REJECT_PASSWORD_REMIND = 1101;
    static REJECT_LOGIN_SERVER_MAINTENANCE = 1811;
    static REJECT_LOGIN_2FA_MISSING_EMAIL_CODE = 1812;

    static REJECT_OPEN_TRADE_BY_BALANCE_REQUIREMENT = 44;

    static REJECT_BY_COUNTRY = 'REJECT_BY_COUNTRY';

    static TICKERS_GROUP_FAVORITE_ID = -1;
    static IS_NOT_ENOUGH_SIZE = -1;

    static SIDE_BUYSELL = -1; // special for SideIcon component
    static SIDE_BUY = 10000;
    static SIDE_SELL = 10001;

    static SIDES = { [Types.SIDE_BUY]: "Buy", [Types.SIDE_SELL]: "Sell" };

    static side(val, defaultValue = "") {
        let res = Types.SIDES[val];
        if (res !== undefined) return res;
        else return defaultValue;
    }

    static ORDER_TYPE_LIMIT = 10030;
    static ORDER_TYPE_MARKET = 10031;
    static ORDER_TYPE_STOP = 10073;
    static ORDER_TYPE_LIMIT_TP = 10074;
    static ORDER_TYPE_STOP_SL = 10075;
    static ORDER_TYPE_NETTING = 10076;
    static ORDER_TYPE_BID_OFFER_STOP = 10078;
    static ORDER_TYPE_BID_OFFER_STOP_SL = 10079;

    static ORDER_TYPES = {
        [Types.ORDER_TYPE_LIMIT]: "Limit",
        [Types.ORDER_TYPE_MARKET]: "Market",
        [Types.ORDER_TYPE_STOP]: "Stop",
        [Types.ORDER_TYPE_LIMIT_TP]: "Limit TP",
        [Types.ORDER_TYPE_STOP_SL]: "Stop SL",
        [Types.ORDER_TYPE_NETTING]: "Netting",
        [Types.ORDER_TYPE_BID_OFFER_STOP]: "Bid-Offer Stop",
        [Types.ORDER_TYPE_BID_OFFER_STOP_SL]: "Bid-Offer Stop SL"
    };

    static ORDER_TRADE_MODE_MARGIN = 0;
    static ORDER_TARDE_MODE_MINING = 1;
    static ORDER_TRADE_MODE_EXCHAGE = 2;

    // Time in force options;
    static ORDER_TIME_IN_FORCE_GTC = 10008;
    static ORDER_TIME_IN_FORCE_IOC = 10010;

    static CL_CODE_MARKET = 4;
    static CL_CODE_STOP = 5;
    static CL_CODE_LIMIT = 6;
    static CL_CODE_MUTUAL = 7;
    static CL_CODE_MARGINCALL = 8;
    static CL_CODE_PARTIAL_MARKET = 13;
    static CL_CODE_PARTIAL_LIMIT = 14;
    static CL_CODE_PARTIAL_STOP = 15;
    static CL_CODE_PARTIAL_MUTUAL = 16;
    static CL_CODE_BIDOFFERSTOP = 21;
    static CL_CODE_PARTIAL_BIDOFFERSTOP = 23;

    static CLOSE_TYPES = {
        [Types.CL_CODE_MARKET]: "Market",
        [Types.CL_CODE_STOP]: "Stop",
        [Types.CL_CODE_LIMIT]: "Limit",
        [Types.CL_CODE_MUTUAL]: "Mutual",
        [Types.CL_CODE_MARGINCALL]: "Margincall",
        [Types.CL_CODE_PARTIAL_MARKET]: "Partial Market",
        [Types.CL_CODE_PARTIAL_LIMIT]: "Partial Limit",
        [Types.CL_CODE_PARTIAL_STOP]: "Partial Stop",
        [Types.CL_CODE_PARTIAL_MUTUAL]: "Partial Mutual",
        [Types.CL_CODE_BIDOFFERSTOP]: "Bidofferstop",
        [Types.CL_CODE_PARTIAL_BIDOFFERSTOP]: "Partial Bidofferstop"

    };

    static orderType(val) {
        let res = Types.ORDER_TYPES[val];
        if (res !== undefined) return res;
        else return "N/A";
    }

    static OP_CODE_MARKET = 0;
    static OP_CODE_STOP = 1;
    static OP_CODE_LIMIT = 2;
    static OP_CODE_NETTING = 3;
    static OP_CODE_MARKET_2 = 4;
    static OP_CODE_STOP_2 = 5;
    static OP_CODE_LIMIT_2 = 6;
    static OP_CODE_NETTING_2 = 7;

    static OP_CODES = {
        [Types.OP_CODE_MARKET]: "Market",
        [Types.OP_CODE_STOP]: "Stop",
        [Types.OP_CODE_LIMIT]: "Limit",
        [Types.OP_CODE_NETTING]: "Netting",
        [Types.OP_CODE_MARKET_2]: "Market",
        [Types.OP_CODE_STOP_2]: "Stop",
        [Types.OP_CODE_LIMIT_2]: "Limit",
        [Types.OP_CODE_NETTING_2]: "Netting"
    };

    static SLTP_UNIT_PERCENT = '%';
    static SLTP_UNIT_PRICE = 'gavel';
    static SLTP_UNIT_MONEY = '$';

    static AUTO_CLOSE_PERCENT = "percent";
    static AUTO_CLOSE_POINT = "point";
    static AUTO_CLOSE_SIZE = "size";

    static opCode(val) {
        let res = Types.OP_CODES[val];
        if (res !== undefined) return res;
        else return "Market";
    }

    static DATE_START_STATISTIC_PERIOD = new Date(2017, 0, 1);

    static DATE_PERIOD_TODAY = 'Today';
    static DATE_PERIOD_YESTERDAY = 'Yesterday';
    static DATE_PERIOD_LAST_WEEK = 'Last week';
    static DATE_PERIOD_LAST_MONTH = 'Last month';
    static DATE_PERIOD_CURRENT_MONTH = 'Current month';
    static DATE_PERIOD_LAST_2_MONTHS = 'Last 2 months';
    static DATE_PERIOD_LAST_3_MONTHS = 'Last 3 months';
    static DATE_PERIOD_CURRENT_QUARTER = 'Current quarter';
    static DATE_PERIOD_LAST_12_MONTHS = 'Last 12 months';
    static DATE_PERIOD_LAST_HALF_YEAR = 'Last 6 months';
    static DATE_PERIOD_LAST_YEAR = 'Last year';
    static DATE_PERIOD_CURRENT_YEAR = 'This year';
    static DATE_PERIOD_ALL = 'All history';
    static DATE_PERIOD_CUSTOM = "Custom";

    static DOWNLOAD_FORMAT_PDF = 'pdf';
    static DOWNLOAD_FORMAT_CSV = 'csv';
    static DOWNLOAD_FORMAT_XLSX = 'xlsx';

    static DST_SCHEME_STANDARD = 'STANDARD';
    static DST_SCHEME_AMERICAN = 'AMERICAN';

    static BALANCE_OPERATION_STATUS = {
        0: 'In progress',
        1: 'Approved',
        2: 'Rejected',
        3: 'Executed',
    };

    static BALANCE_OPERATION_TYPES = {
        0: 'Deposit',
        1: 'Withdraw',
        2: 'Deposit to credit',
        3: 'Withdraw to credit',
    };

    static SOUND_GROUP_CLOSE_POSITION = 'closePositionSoundGroup';
    static SOUND_GROUP_SUBMIT_MARKET_ORDER = 'submitMarketOrderSoundGroup';
    static SOUND_GROUP_SUBMIT_PENDING_ORDER = 'submitPendingOrderSoundGroup';
    static SOUND_GROUP_CANCEL_ORDER = 'cancelOrderSoundGroup';

    static EVENT_SOUND_DEPOSIT = 'SR01';
    static EVENT_SOUND_WITHDRAW = 'SR02';
    static EVENT_SOUND_POSITION_CLOSED = 'SR03';
    static EVENT_SOUND_POSITION_CLOSED_PLUS = 'SR04';
    static EVENT_SOUND_POSITION_CLOSED_MINUS = 'SR05';
    static EVENT_SOUND_POSITION_OPENED = 'SR06';
    static EVENT_SOUND_ORDER_OPENED = 'SR07';
    static EVENT_SOUND_SWITCH_ON = 'SR08';
    static EVENT_SOUND_SWITCH_OFF = 'SR09';
    static EVENT_SOUND_NOTICE_DEFAULT = 'SR10';
    static EVENT_SOUND_NOTICE_MINOR = 'SR11';
    static EVENT_SOUND_TABS_1 = 'SR12';
    static EVENT_SOUND_TABS_2 = 'SR13';
    static EVENT_SOUND_TABS_3 = 'SR14';
    static EVENT_SOUND_CLICK = 'SR15';

    static HOLD_PNL_TYPE_DISABLE = 0;
    static HOLD_PNL_TYPE_BASEUNIT = 1;
    static HOLD_PNL_TYPE_PERCENT = 2;

    // типы оплат которые будут доступны в списке через которые можно совершить перевод
    static PAYMENT_TYPE_VISA_PAY_BOUTIQUE = 'VISA_PAY_BOUTIQUE'; // метод 1
    static PAYMENT_TYPE_VISA_AON_PAY = 'VISA_AON_PAY'; // метод 2
    static PAYMENT_TYPE_VISA_EZ_PAY = 'VISA_VISA_EZ_PAY'; // метод 3 (Zoompay)
    static PAYMENT_TYPE_VISA_DREAMS_PAY = 'VISA_DREAMS_PAY'; // метод 4
    static PAYMENT_TYPE_WIRE_TRANSFER = 'WIRE_TRANSFER'; // банк.
    static PAYMENT_PHOENIX_PAYMENTS_LTD = 'PAYMENT_PHOENIX_PAYMENTS_LTD'; // банк. перевод
    static PAYMENT_TYPE_WEBMONEY = 'WEBMONEY';
    static PAYMENT_TYPE_QIWI = 'QIWI';
    static PAYMENT_TYPE_QIWI2 = 'QIWI2';
    static PAYMENT_TYPE_ALFA_CLICK = 'ALFA_CLICK';
    static PAYMENT_TYPE_PS_BANK = 'PS_BANK';
    static PAYMENT_TYPE_YANDEX_MONEY = 'YANDEX_MONEY';
    static PAYMENT_TYPE_YANDEX_MONEY2 = 'YANDEX_MONEY2';
    static PAYMENT_TYPE_MIDEX_WIDGET = 'PAYMENT_TYPE_MIDEX_WIDGET';
    static PAYMENT_TYPE_MIDEX_PAYMENT = 'PAYMENT_TYPE_MIDEX_PAYMENT';
    static PAYMENT_TYPE_BABEL = 'BABEL';
    static PAYMENT_TYPE_BIGXCOIN = 'BIGXCOIN';
    static PAYMENT_TYPE_BITCOIN = 'Bitcoin';
    static PAYMENT_TYPE_BITCOIN_CASH = 'BitcoinCash';
    static PAYMENT_TYPE_BITCOIN_GOLD = 'BITCOIN_GOLD';
    static PAYMENT_TYPE_BITCOIN_SV = 'BITCOIN_SV';
    static PAYMENT_TYPE_DASHCOIN = 'DASHCOIN';
    static PAYMENT_TYPE_EOS = 'EOS';
    static PAYMENT_TYPE_ETHEREUM = 'Ethereum';
    static PAYMENT_TYPE_ETHEREUM_CLASSIC = 'ETHEREUM_CLASSIC';
    static PAYMENT_TYPE_LITECOIN = 'Litecoin';
    static PAYMENT_TYPE_OMISEGO = 'OMISEGO';
    static PAYMENT_TYPE_RIPPLE = 'Ripple';
    static PAYMENT_TYPE_USDT = 'USDT';
    static PAYMENT_TYPE_ZCASH = 'ZCASH';
    static PAYMENT_TYPE_ZRX = 'ZRX';
    static PAYMENT_TYPE_INTERNAL_ACCOUNTS = 'INTERNAL_ACCOUNTS';
    static PAYMENT_TYPE_ALI_PAY = 'ALIPAY';
    static PAYMENT_TYPE_CUP = 'CUP';
    static PAYMENT_TYPE_WE_CHAP = 'WE_CHAP';
    static PAYMENT_TYPE_MINING_SERVER = 'MINING_SERVER';
    static PAYMENT_TYPE_SIMPLEX = 'SIMPLEX';
    static PAYMENT_TYPE_MIDEXPAY = 'MIDEXPAY';
    static PAYMENT_TYPE_POWERPAY = 'POWERPAY';
    static PAYMENT_TYPE_POWERPAY_SEPA = 'POWERPAY_SEPA';
    static PAYMENT_TYPE_IBAN = 'IBAN';
    static PAYMENT_TYPE_CLEAR_JUNCTION = 'CLEAR_JUNCTION';
    static PAYMENT_TYPE_CERTUS = 'CERTUS';
    static PAYMENT_TYPE_PAYADVISERS = 'PAYADVISERS';
    static PAYMENT_TYPE_ADV_CASH = 'ADV_CASH';
    static PAYMENT_TYPE_PLC = 'PLC';
    static PAYMENT_TYPE_MIDEXPAY_WIDGET = 'MIDEXPAY_WIDGET';
    static PAYMENT_TYPE_FREE_KASSA = 'FREE_KASSA';
    static PAYMENT_TYPE_INTER_KASSA = 'INTER_KASSA';
    static PAYMENT_TYPE_INTER_KASSA_VISA = 'INTER_KASSA_VISA';
    static PAYMENT_TYPE_INTER_KASSA_MASTERCARD = 'INTER_KASSA_MASTERCARD';
    static PAYMENT_TYPE_INTER_KASSA_MIR = 'INTER_KASSA_MIR';
    static PAYMENT_TYPE_PAYEER = 'PAYEER';
    static PAYMENT_TYPE_ENSOPAYMENT = 'ENSOPAYMENT';
    static PAYMENT_TYPE_PAYOP = 'PAYOP';
    static PAYMENT_TYPE_PAYTHRONE_YANDEX_MONEY = "PAYTHRONE_YANDEX_MONEY";
    static PAYMENT_TYPE_BETATRANSFER = "BETATRANSFER";
    static PAYMENT_TYPE_INTERNETCASHBANK = "INTERNETCASHBANK";
    static PAYMENT_TYPE_PORTMONE = "PORTMONE";
    static PAYMENT_TYPE_UAPAYUA = "UAPAYUA";
    static PAYMENT_TYPE_PALMIRA = "PALMIRA";
    static PAYMENT_TYPE_ROCKETBIT = "ROCKETBIT";
    static PAYMENT_TYPE_EASYPORTAL = "EASYPORTAL";
    static PAYMENT_TYPE_IQUICK = "IQUICK";
    static PAYMENT_TYPE_STARBILL = "STARBILL";
    static PAYMENT_TYPE_STARBILL = "STARBILL2";
    static PAYMENT_TYPE_STARBILL3 = "STARBILL3";
    static PAYMENT_TYPE_VISA_MASTERCARD_QIWI = "VISA_MASTERCARD_QIWI";
    static PAYMENT_TYPE_WALLETIX = "Walletix";
    static PAYMENT_TYPE_ALFA_CASH = "ALFA_CASH";
    static PAYMENT_TYPE_ADV_CASH_REDIRECT = "ADV_CASH_REDIRECT";
    static PAYMENT_TYPE_APPLE_PAY = "APPLE_PAY";
    static PAYMENT_TYPE_GOOGLE_PAY = "GOOGLE_PAY";
    static PAYMENT_TYPE_QIWI_REDIRECT = "QIWI_REDIRECT";
    static PAYMENT_TYPE_PAYEER_REDIRECT = "PAYEER_REDIRECT";
    static PAYMENT_TYPE_BTQ_FINANCE = "BTQ_FINANCE";
    static PAYMENT_TYPE_STRIPE = "STRIPE";
    static PAYMENT_TYPE_NEW_QIWI = "NEW_QIWI";
    static PAYMENT_TYPE_NEW_BINANCE = "NEW_BINANCE";
    static PAYMENT_TYPE_NEW_PAYEER = "NEW_PAYEER";
    static PAYMENT_TYPE_NEW_BESTCHANGE = "NEW_BESTCHANGE";
    static PAYMENT_TYPE_NEW_VISA = "NEW_VISA";
    static PAYMENT_TYPE_NEW_MASTERCARD = "NEW_MASTERCARD";
    static PAYMENT_TYPE_NEW_YANDEX_MONEY = "NEW_YANDEX_MONEY";
    static PAYMENT_TYPE_NEW_WEBMONEY = "NEW_WEBMONEY";
    static PAYMENT_TYPE_NEW_MIR = "NEW_MIR";
    static PAYMENT_TYPE_NEW_CARD_QIWI = "NEW_CARD_QIWI";
    static PAYMENT_TYPE_NEW_CARD_BINANCE = "NEW_CARD_BINANCE";
    static PAYMENT_TYPE_NEW_CARD_PAYEER = "NEW_CARD_PAYEER";
    static PAYMENT_TYPE_HR_24 = "HR-24";
    static PAYMENT_TYPE_USDT_TRC20 = "USDT_TRC20";
    static PAYMENT_TYPE_FINANIC_IO = "finanic.io";
    static PAYMENT_TYPE_PAY_POUND = "PayPound";
    static PAYMENT_TYPE_PR_MONEY = "PrMoney";
    static PAYMENT_TYPE_AZUL_PAY = "Azulpay";
    static PAYMENT_TYPE_GROW_PAY = "GrowPay";
    static PAYMENT_TYPE_BETATRANSFER_QIWI = "Betatransfer(Qiwi)";
    static PAYMENT_TYPE_BETATRANSFER_YOOMONEY = "Betatransfer(YooMoney)";
    static PAYMENT_TYPE_BETATRANSFER_P2R = "Betatransfer(P2R)";
    static PAYMENT_TYPE_VILPAY = "Vilpay";
    static PAYMENT_TYPE_WELLCOINEX = "Wellcoinex";
    static PAYMENT_TYPE_TRANSFER_MONEY = "TRANSFER_MONEY";
    static PAYMENT_TYPE_VISA_MK_MIR = 'PAYMENT_TYPE_VISA_MK_MIR';
    static PAYMENT_TYPE_PAY2PAY = 'PAYMENT_TYPE_PAY2PAY';
    static PAYMENT_TYPE_FIREPAY = "FIREPAY";
    static PAYMENT_TYPE_PAYPORT = "PAYPORT";
    static PAYMENT_TYPE_EXPAY = "EXPAY";
    static PAYMENT_TYPE_AIFORY = "AIFORY";
    static PAYMENT_TYPE_VISA_TURKEY = "VISA_TURKEY";
    static PAYMENT_TYPE_DEPOSIT = "DEPOSIT";
    static PAYMENT_TYPE_RQUEEN = "RQUEEN";
    static PAYMENT_TYPE_TAP_BANK = "TapBank";
    static PAYMENT_TYPE_PLAT_CORE = "PlatCore";
    static PAYMENT_TYPE_CRYPTO_NETS = "CRYPTO_NETS";
    static PAYMENT_TYPE_PAYTURCA = "PayTurca";

    // Fireblocks methods
    static PAYMENT_TYPE_FIREBLOCKS_AAVE = "FIREBLOCKS_AAVE";
    static PAYMENT_TYPE_FIREBLOCKS_ADA = "FIREBLOCKS_ADA";
    static PAYMENT_TYPE_FIREBLOCKS_ALGO = "FIREBLOCKS_ALGO";
    static PAYMENT_TYPE_FIREBLOCKS_APE = "FIREBLOCKS_APE";
    static PAYMENT_TYPE_FIREBLOCKS_ATOM = "FIREBLOCKS_ATOM";
    static PAYMENT_TYPE_FIREBLOCKS_AUDIO = "FIREBLOCKS_AUDIO";
    static PAYMENT_TYPE_FIREBLOCKS_AVAX = "FIREBLOCKS_AVAX";
    static PAYMENT_TYPE_FIREBLOCKS_AXS = "FIREBLOCKS_AXS";
    static PAYMENT_TYPE_FIREBLOCKS_BCH = "FIREBLOCKS_BCH";
    static PAYMENT_TYPE_FIREBLOCKS_BTC = "FIREBLOCKS_BTC";
    static PAYMENT_TYPE_FIREBLOCKS_BTTC = "FIREBLOCKS_BTTC";
    static PAYMENT_TYPE_FIREBLOCKS_CAKE = "FIREBLOCKS_CAKE";
    static PAYMENT_TYPE_FIREBLOCKS_CHZ = "FIREBLOCKS_CHZ";
    static PAYMENT_TYPE_FIREBLOCKS_DAI = "FIREBLOCKS_DAI";
    static PAYMENT_TYPE_FIREBLOCKS_DSH = "FIREBLOCKS_DSH";
    static PAYMENT_TYPE_FIREBLOCKS_DOGE = "FIREBLOCKS_DOGE";
    static PAYMENT_TYPE_FIREBLOCKS_EOS = "FIREBLOCKS_EOS";
    static PAYMENT_TYPE_FIREBLOCKS_ETC = "FIREBLOCKS_ETC";
    static PAYMENT_TYPE_FIREBLOCKS_ETH = "FIREBLOCKS_ETH";
    static PAYMENT_TYPE_FIREBLOCKS_GALA = "FIREBLOCKS_GALA";
    static PAYMENT_TYPE_FIREBLOCKS_IOTA = "FIREBLOCKS_IOTA";
    static PAYMENT_TYPE_FIREBLOCKS_LINK = "FIREBLOCKS_LINK";
    static PAYMENT_TYPE_FIREBLOCKS_LTC = "FIREBLOCKS_LTC";
    static PAYMENT_TYPE_FIREBLOCKS_LUNA = "FIREBLOCKS_LUNA";
    static PAYMENT_TYPE_FIREBLOCKS_MKR = "FIREBLOCKS_MKR";
    static PAYMENT_TYPE_FIREBLOCKS_MATIC = "FIREBLOCKS_MATIC";
    static PAYMENT_TYPE_FIREBLOCKS_MANA = "FIREBLOCKS_MANA";
    static PAYMENT_TYPE_FIREBLOCKS_ONE = "FIREBLOCKS_ONE";
    static PAYMENT_TYPE_FIREBLOCKS_DOT = "FIREBLOCKS_DOT";
    static PAYMENT_TYPE_FIREBLOCKS_RUNE = "FIREBLOCKS_RUNE";
    static PAYMENT_TYPE_FIREBLOCKS_SAND = "FIREBLOCKS_SAND";
    static PAYMENT_TYPE_FIREBLOCKS_SHIB = "FIREBLOCKS_SHIB";
    static PAYMENT_TYPE_FIREBLOCKS_SOL = "FIREBLOCKS_SOL";
    static PAYMENT_TYPE_FIREBLOCKS_TRX = "FIREBLOCKS_TRX";
    static PAYMENT_TYPE_FIREBLOCKS_UNI = "FIREBLOCKS_UNI";
    static PAYMENT_TYPE_FIREBLOCKS_USDT_ERC20 = "FIREBLOCKS_USDT_ERC20";
    static PAYMENT_TYPE_FIREBLOCKS_USDT_TRC20 = "FIREBLOCKS_USDT_TRC20";
    static PAYMENT_TYPE_FIREBLOCKS_XLM = "FIREBLOCKS_XLM";
    static PAYMENT_TYPE_FIREBLOCKS_XRP = "FIREBLOCKS_XRP";
    static PAYMENT_TYPE_FIREBLOCKS_XTZ = "FIREBLOCKS_XTZ";

    // через что пополнение
    static PAYMENT_SYSTEM_DEFAULT = 0;
    static PAYMENT_SYSTEM_DEPOSIT = 1;
    static PAYMENT_SYSTEM_ORANGE_PAY = 2;
    static PAYMENT_SYSTEM_WEB_MONEY = 3;
    static PAYMENT_SYSTEM_QIWI = 4;
    static PAYMENT_SYSTEM_ALFA_CLICK = 5;
    static PAYMENT_SYSTEM_PS_BANK = 6;
    static PAYMENT_SYSTEM_YANDEX_MONEY = 7;
    static PAYMENT_SYSTEM_B2B_BIN_PAY = 8;
    static PAYMENT_SYSTEM_PAY_BOUTIQUE = 9;
    static PAYMENT_SYSTEM_EZ_PAY = 25; // Zoompay
    static PAYMENT_SYSTEM_DREAMS_PAY = 11;
    static PAYMENT_SYSTEM_ALI_PAY = 12;
    static PAYMENT_SYSTEM_CUP = 13;
    static PAYMENT_SYSTEM_WE_CHAP = 14;
    static PAYMENT_SYSTEM_QIWI2 = 15;
    static PAYMENT_SYSTEM_YANDEX_MONEY2 = 16;
    static PAYMENT_SYSTEM_FREE_KASSA = 17;
    static PAYMENT_SYSTEM_QIWI_INTERCASSA = 21;
    static PAYMENT_SYSTEM_YANDEX_MONEY_INTERCASSA = 22;
    static PAYMENT_SYSTEM_ALFA_CLICK_INTERCASSA = 23;
    static PAYMENT_SYSTEM_INTER_KASSA = 24;
    static PAYMENT_SYSTEM_INTER_KASSA_VISA = 34;
    static PAYMENT_SYSTEM_INTER_KASSA_MASTERCARD = 35;
    static PAYMENT_SYSTEM_INTER_KASSA_MIR = 36;
    static PAYMENT_SYSTEM_SIMPLEX = 26;
    static PAYMENT_SYSTEM_SAFECURR = 27;
    static PAYMENT_SYSTEM_POWERPAY = 28;
    static PAYMENT_SYSTEM_POWERPAY_SEPA = 29;
    static PAYMENT_SYSTEM_IBAN = 253;
    static PAYMENT_SYSTEM_PAYEER = 30;
    static PAYMENT_SYSTEM_CLEAR_JUNCTION = 32;
    static PAYMENT_SYSTEM_CERTUS = 33;
    static PAYMENT_SYSTEM_PAYADVISERS = 33;
    static PAYMENT_SYSTEM_ADV_CASH = 39;
    static PAYMENT_SYSTEM_BETATRANSFER = 44;
    static PAYMENT_SYSTEM_PAYTHRONE_YANDEX_MONEY = 49;
    static PAYMENT_SYSTEM_PAYOP = 50;
    static PAYMENT_SYSTEM_ENSOPAYMENT = 53;
    static PAYMENT_SYSTEM_INTERNETCASHBANK = 58;
    static PAYMENT_SYSTEM_PORTMONE = 59;
    static PAYMENT_SYSTEM_UAPAYUPA = 60;
    static PAYMENT_SYSTEM_PALMIRA = 61;
    static PAYMENT_SYSTEM_ROCKETBIT = 62;
    static PAYMENT_SYSTEM_EASYPORTAL = 63;
    static PAYMENT_SYSTEM_STARBILL = 64;
    static PAYMENT_SYSTEM_WALLETIX = 65;
    static PAYMENT_SYSTEM_BTQ_FINANCE = 66;

    static PAYMENT_SYSTEM_HR_24 = 68;
    static PAYMENT_SYSTEM_FINANIC_IO = 69;
    static PAYMENT_SYSTEM_PAY_POUND = 70;
    static PAYMENT_SYSTEM_PR_MONEY = 71;
    static PAYMENT_SYSTEM_STARBILL2 = 73;
    static PAYMENT_SYSTEM_BETATRANSFER_QIWI = 74;
    static PAYMENT_SYSTEM_BETATRANSFER_YOOMONEY = 75;
    static PAYMENT_SYSTEM_BETATRANSFER_P2R = 76;
    static PAYMENT_SYSTEM_AZUL_PAY = 77;
    static PAYMENT_SYSTEM_GROW_PAY = 78;
    static PAYMENT_SYSTEM_STARBILL3 = 79;
    static PAYMENT_SYSTEM_VILPAY = 80;
    static PAYMENT_SYSTEM_WELLCOINEX = 81;

    static PAYMENT_SYSTEM_FIREPAY = 83;
    static PAYMENT_SYSTEM_PAYPORT = 84;
    static PAYMENT_SYSTEM_EXPAY = 85;
    static PAYMENT_SYSTEM_PAY2PAY = 86;
    static PAYMENT_SYSTEM_AIFORY = 87;
    static PAYMENT_SYSTEM_VISA_TURKEY = 88;
    static PAYMENT_SYSTEM_RQUEEN = 89;
    static PAYMENT_SYSTEM_TAP_BANK = 90;
    static PAYMENT_SYSTEM_PLAT_CORE = 91;
    static PAYMENT_SYSTEM_PAYTURCA = 92;

    static PAYMENT_SYSTEM_NEW_PAY = 254;

    // чем пополнение
    static PAYMENT_METHOD_VISA = 1;
    static PAYMENT_METHOD_WIRE = 2;
    static PAYMENT_METHOD_EWallet = 3;
    static PAYMENT_METHOD_MINING_SERVER = 7;
    static PAYMENT_METHOD_CRYPTO = 9;

    static PAYMENT_METHODS = [
        Types.PAYMENT_METHOD_VISA,
        Types.PAYMENT_METHOD_WIRE,
        Types.PAYMENT_METHOD_EWallet,
        Types.PAYMENT_METHOD_MINING_SERVER,
        Types.PAYMENT_METHOD_CRYPTO,
    ];

    static PAYMENT_METHOD_NAMES = {
        [Types.PAYMENT_METHOD_VISA]: 'Visa',
        [Types.PAYMENT_METHOD_WIRE]: 'Wire',
        [Types.PAYMENT_METHOD_EWallet]: 'Wallet',
        [Types.PAYMENT_METHOD_MINING_SERVER]: 'Mining',
        [Types.PAYMENT_METHOD_CRYPTO]: 'Crypto',
    };

    static CARD_PAYMENT_SYSTEM_VISA = 'VISA';
    static CARD_PAYMENT_SYSTEM_MASTER_CARD = 'MASTER_CARD';
    static CARD_PAYMENT_SYSTEM_MAESTRO = 'MAESTRO';

    // Crypto Address Type
    static CRYPTO_ADDRESS_TYPE_BITCOIN = 0;
    static CRYPTO_ADDRESS_TYPE_ETHEREUM = 1;
    static CRYPTO_ADDRESS_TYPE_BITCOIN_CASH = 2;
    static CRYPTO_ADDRESS_TYPE_LITECOIN = 3;
    static CRYPTO_ADDRESS_TYPE_RIPPLE = 4;
    static CRYPTO_ADDRESS_TYPE_USDT = 5;

    static CRYPTO_CURRENCY_ADDRESS_TYPE = {
        BTC: Types.CRYPTO_ADDRESS_TYPE_BITCOIN,
        ETH: Types.CRYPTO_ADDRESS_TYPE_ETHEREUM,
        BCH: Types.CRYPTO_ADDRESS_TYPE_BITCOIN_CASH,
        LTC: Types.CRYPTO_ADDRESS_TYPE_LITECOIN,
        XRP: Types.CRYPTO_ADDRESS_TYPE_RIPPLE,
        USDT: Types.CRYPTO_ADDRESS_TYPE_USDT,
    };

    static SPREAD_IN_LOTS = 'SPREAD_IN_LOTS';
    static SPREAD_IN_USD = 'SPREAD_IN_USD';

    // OrderForm modes
    static ORDERFORM_CORRECT_SL_TP = "MODE_CORRECT_SL_TP";
    static ORDERFORM_VALIDATE_SL_TP = "MODE_VALIDATE_SL_TP";
    static ORDERFORM_NOOP = "MODE_NOOP";

    // Deposit payment step id
    static DEPOSIT_STEP_FORM = "form";
    static DEPOSIT_STEP_REGISTRATION = "reg";
    static DEPOSIT_STEP_VERIFY_PHONE = "phone";
    static DEPOSIT_STEP_KYC = "kyc";
    static DEPOSIT_STEP_DEPOSIT = "deposit";

    // User docs file types
    static FILE_TYPE = {
        other: 0,
        identity: 1,
        address: 2,
        card: 3,
        photoWithPassport: 4,
        questionnaires1: 5,
        photoCardBackgroundSite: 6,
        questionnaires2: 7,
    };

    // User docs file status
    static FILE_STATUS = {
        inprogress: 0,
        approved: 1,
        rejected: 2,
        manual: 3,
    };

    // User docs file status name
    static FILE_STATUS_NAME = {
        [Types.FILE_STATUS.inprogress]: "In progress1",
        [Types.FILE_STATUS.approved]: "Approved",
        [Types.FILE_STATUS.rejected]: "Rejected1",
        [Types.FILE_STATUS.manual]: "Manual",
    };

    static OBJECT_EDIT_PROPS = 'Edit object';
    static OBJECT_EDIT_STROKE = 'Edit object stroke';
    static OBJECT_EDIT_FILL = 'Edit object fill';
    static OBJECT_EDIT_LINE_TYPE = 'Edit object line type';
    static OBJECT_EDIT_LINE_WIDTH = 'Edit object line width';
    static OBJECT_EDIT_FONT_TYPE = 'Edit object font type';
    static OBJECT_EDIT_FONT_SIZE = 'Edit object font size';
    static OBJECT_EDIT_HIDE = 'Edit object hide';
    static OBJECT_REMOVE = 'Remove object';

    static CHART_ITEM_TYPE = {
        DRAW: 'draw',
        INDICATOR: 'indicator',
    };

    static HISTORY_TYPE_EXCHANGE = "EXCHANGE";
    static HISTORY_TYPE_ACCOUNT = "ACCOUNT";
    static HISTORY_TYPE_TRANSACTION = "TRANSACTION";

    static FILTERS_TYPE_MARGINAL = "marginal";
    static FILTERS_TYPE_EXCHANGE = "exchange";
    static FILTERS_TYPE_BALANCE = "balance";

    static FILTERS_SIDE_BUY = "buy";
    static FILTERS_SIDE_SELL = "sell";
    static FILTERS_SIDE_DEPOSIT = "deposit";
    static FILTERS_SIDE_WITHDRAW = "withdraw";

    static REPORT_ITEM_TYPE_MARGIN = "margin";
    static REPORT_ITEM_TYPE_EXCHANGE = "exchange";
    static REPORT_ITEM_TYPE_SWAP = "swap";
    static REPORT_ITEM_TYPE_DIVIDENDS = "dividends";
    static REPORT_ITEM_TYPE_BALANCE = "balance";
    static REPORT_ITEM_TYPE_COMMISSIONS = "commissions";
    static REPORT_ITEM_TYPE_REJECTED = "rejected";
    static REPORT_ITEM_TYPE_NONE = "none";

    /**
     *
     * @param period
     * @param from
     * @param to
     * @param todayTime
     * @returns {{from: Date, to: Date}}
     */
    static datePeriod(period, from = null, to = null, todayTime = Date.now()) {
        const todayEndDay = new Date(todayTime);
        const currentQuarter = moment(todayEndDay).quarter();
        todayEndDay.setDate(todayEndDay.getDate() + 1);
        todayEndDay.setHours(0);
        todayEndDay.setMinutes(0);
        todayEndDay.setSeconds(0);
        todayEndDay.setMilliseconds(0);

        switch (period) {
            case Types.DATE_PERIOD_YESTERDAY:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth(), todayEndDay.getDate() - 2),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_LAST_WEEK:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth(), todayEndDay.getDate() - 8),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_LAST_MONTH:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth() - 1, todayEndDay.getDate() - 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_CURRENT_MONTH:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth(), 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_LAST_2_MONTHS:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth() - 2, todayEndDay.getDate() - 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_LAST_3_MONTHS:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth() - 3, todayEndDay.getDate() - 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_CURRENT_QUARTER:
                return {
                    from: new Date(todayEndDay.getFullYear(), (currentQuarter - 1) * 3, 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_LAST_HALF_YEAR:
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth() - 6, todayEndDay.getDate() - 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_CURRENT_YEAR:
                return {
                    from: new Date(todayEndDay.getFullYear(), 0, 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_LAST_YEAR:
                return {
                    from: new Date(todayEndDay.getFullYear() -1, todayEndDay.getMonth(), todayEndDay.getDate() - 1),
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_ALL:
                return {
                    from: Types.DATE_START_STATISTIC_PERIOD,
                    to: todayEndDay,
                };

            case Types.DATE_PERIOD_CUSTOM:
                return {
                    from: from ? from : new Date(todayEndDay.getFullYear(), todayEndDay.getMonth(), todayEndDay.getDate() - 1),
                    to: to ? to : todayEndDay,
                };

            default:
                // today
                return {
                    from: new Date(todayEndDay.getFullYear(), todayEndDay.getMonth(), todayEndDay.getDate() - 1),
                    to: todayEndDay,
                }
        }
    }


    /**
     * StockChart Frames
     */
    static BASE_CHART_FRAME_SECOND = "BASE_CHART_FRAME_SECOND";
    static BASE_CHART_FRAME_MINUTE = "BASE_CHART_FRAME_MINUTE";
    static BASE_CHART_FRAME_HOUR = "BASE_CHART_FRAME_HOUR";

    static CHART_FRAMES = new Map({
        "20FS": {
            id: 10,
            value: 20,
            label: "20 seconds",
            labelShort: "s",
            resolution: "20FS",
            timeInterval: "60",
            timeFrame:"20FS",
            historyPeriod: 360,
            offset: 20,
            baseFrame: Types.BASE_CHART_FRAME_SECOND,
            duration: 20, // time interval in seconds,
            type: "frame",
            isVisible: true
        },
        "30FS": {
            id: 11,
            value: 30,
            label: "30 seconds",
            labelShort: "s",
            resolution: "30FS",
            timeInterval: "60",
            timeFrame:"30FS",
            historyPeriod: 360,
            offset: 30,
            baseFrame: Types.BASE_CHART_FRAME_SECOND,
            duration: 30,
            type: "frame",
            isVisible: true
        },
        "1F": {
            id: 12,
            value: 1,
            label: "1 minute",
            labelShort: "m",
            resolution: "1F",
            timeInterval: "60",
            timeFrame: "1F",
            historyPeriod: 2,
            offset: 1,
            baseFrame: Types.BASE_CHART_FRAME_MINUTE,
            duration: 60,
            type: "frame",
            isVisible: true
        },
        "3F": {
            id: 13,
            value: 3,
            label: "3 minute",
            labelShort: "m",
            resolution: "3F",
            timeInterval: "60",
            timeFrame: "3F",
            historyPeriod: 2,
            offset: 3,
            baseFrame: Types.BASE_CHART_FRAME_MINUTE,
            duration: 3 * 60,
            type: "frame",
            isVisible: true
        },
        "5F": {
            id: 14,
            value: 5,
            label: "5 minutes",
            labelShort: "m",
            resolution: "5F",
            timeInterval: "360",
            timeFrame: "5F",
            historyPeriod: 3,
            offset: 5,
            baseFrame: Types.BASE_CHART_FRAME_MINUTE,
            duration: 5 * 60,
            type: "frame",
            isVisible: true
        },
        "15F": {
            id: 15,
            value: 15,
            label: "15 minutes",
            labelShort: "m",
            resolution: "15F",
            timeInterval: "720",
            timeFrame:"15F",
            historyPeriod: 40,
            offset: 15,
            baseFrame: Types.BASE_CHART_FRAME_MINUTE,
            duration: 15 * 60,
            type: "frame",
            isVisible: true
        },
        "30F": {
            id: 16,
            value: 30,
            label: "30 minutes",
            labelShort: "m",
            resolution: "30F",
            timeInterval: "D",
            timeFrame: "30F",
            historyPeriod: 40,
            offset: 30,
            baseFrame: Types.BASE_CHART_FRAME_MINUTE,
            duration: 30 * 60,
            type: "frame",
            isVisible: false
        },
        "60F": {
            id: 17,
            value: 1,
            label: "1 hour",
            labelShort: "shortHour",
            resolution: "60F",
            timeInterval: "D",
            timeFrame:"60F",
            historyPeriod: 40,
            offset: 60,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 3600, // 3 * 3600,
            type: "frame",
            isVisible: true
        },
        "180F": {
            id: 18,
            value: 3,
            label: "3 hours",
            labelShort: "shortHour",
            resolution: "180F",
            timeInterval: "W",
            timeFrame:"180F",
            historyPeriod: 60,
            offset: 180,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 3600, // 3 * 3600,
            type: "frame",
            isVisible: false
        },
        "240F": {
            id: 19,
            value: 4,
            label: "4 hours",
            labelShort: "shortHour",
            resolution: "240F",
            timeInterval: "W",
            timeFrame:"240F",
            historyPeriod: 60,
            offset: 240,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 3600, // 3 * 3600,
            type: "frame",
            isVisible: true
        },
        "720F": {
            id: 20,
            value: 12,
            label: "12 hours",
            labelShort: "shortHour",
            resolution: "720F",
            timeInterval: "M",
            timeFrame: "720F",
            historyPeriod: 90,
            offset: 720,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 12 * 3600,
            type: "frame",
            isVisible: true
        },
        "DF": {
            id: 21,
            value: 1,
            label: "1 day",
            labelShort: "D",
            resolution: "DF",
            timeInterval: "3M",
            timeFrame:"DF",
            historyPeriod: 150,
            offset: 1440,
            baseFrame: Types.BASE_CHART_FRAME_MINUTE,
            duration: 24 * 3600,
            type: "frame",
            isVisible: true,
            nextChartFrame: "6M",
        },
        "WF": {
            id: 22,
            value: 1,
            label: "7 days",
            labelShort: "W",
            resolution: "WF",
            timeInterval: "1Y",
            timeFrame: "WF",
            historyPeriod: 1000,
            offset: 30 * 1440,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            isVisible: true,
            duration: 7 * 24 * 3600,
            type: "frame",
            nextChartFrame: "3Y",
            prevChartFrame: "6M"
        },
        "MF": {
            id: 23,
            value: 1,
            label: "1 month",
            labelShort: "shortMonth",
            resolution: "MF",
            timeInterval: "3Y",
            timeFrame: "MF",
            historyPeriod: 2000,
            offset: 30 * 1440,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 30 * 24 * 3600,
            type: "frame",
            isVisible: true
        },
        // intervals
        "30": {
            id: 24,
            value: 30,
            label: "30 minutes",
            labelShort: "m",
            resolution: "30FS",
            timeInterval:"30",
            timeFrame:"30FS",
            historyPeriod: 3,
            offset: 30,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 30 * 60,
            type: "interval",
            isVisible: false
        },
        "60": {
            id: 25,
            value: 1,
            label: "1 hour",
            labelShort: "shortHour",
            resolution: "60",
            timeInterval:"60",
            timeFrame: "1F",
            historyPeriod: 7,
            offset: 1,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 60 * 60,
            type: "interval",
            isVisible: true
        },
        "180": {
            id: 27,
            value: 3,
            label: "3 hours",
            labelShort: "shortHour",
            resolution: "180",
            timeInterval:"180",
            timeFrame: "3F",
            historyPeriod: 7,
            offset: 5,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 3 * 3600,
            type: "interval",
            isVisible: false
        },
        "360": {
            id: 29,
            value: 6,
            label: "6 hours",
            labelShort: "shortHour",
            resolution: "360",
            timeInterval: "360",
            timeFrame: "5F",
            historyPeriod: 7,
            offset: 5,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 6 * 3600,
            type: "interval",
            isVisible: false
        },
        "720": {
            id: 30,
            value: 12,
            label: "12 hours",
            labelShort: "shortHour",
            resolution: "720",
            timeInterval:"720",
            timeFrame:"15F",
            historyPeriod: 14,
            offset: 15,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 12 * 3600,
            type: "interval",
            isVisible: true
        },
        "D": {
            id: 31,
            value: 1,
            label: "1 day",
            labelShort: "D",
            resolution: "60F",
            timeInterval:"D",
            timeFrame:"60F",
            historyPeriod: 14,
            offset: 30,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 24 * 3600,
            type: "interval",
            isVisible: true
        },
        "W": {
            id: 32,
            value: 1,
            label: "7 days",
            labelShort: "W",
            resolution: "240F",
            timeInterval:"W",
            timeFrame: "240F",
            historyPeriod: 60,
            offset: 60,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 7 * 24 * 3600,
            type: "interval",
            isVisible: true
        },
        "M": {
            id: 33,
            value: 1,
            label: "1 month",
            labelShort: "shortMonth",
            resolution: "M",
            timeInterval: "M",
            timeFrame:"720F",
            historyPeriod: 150,
            offset: 60 * 12,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 30 * 24 * 3600,
            type: "interval",
            isVisible: true
        },
        "3M": {
            id: 34,
            value: 3,
            label: "3 month",
            labelShort: "shortMonth",
            resolution: "3M",
            timeInterval:"3M",
            timeFrame: "DF",
            historyPeriod: 150,
            offset: 1440,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 3 * 30 * 24 * 3600,
            type: "interval",
            isVisible: true,
            nextChartFrame: "6M"
        },
        "6M": {
            id: 35,
            value: 6,
            label: "6 month",
            labelShort: "shortMonth",
            resolution: "6M",
            timeInterval: "6M",
            timeFrame: "WF",
            historyPeriod: 900,
            offset: 7 * 1440,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 6 * 30 * 24 * 3600,
            type: "interval",
            isVisible: true,
            nextChartFrame: "1Y",
            prevChartFrame: "3M"
        },
        "1Y": {
            id: 36,
            value: 1,
            label: "1 year",
            labelShort: "Y",
            resolution: "1Y",
            timeInterval: "1Y",
            timeFrame: "WF",
            historyPeriod: 900,
            offset: 7 * 1440,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 365 * 24 * 3600,
            type: "interval",
            isVisible: true,
            prevChartFrame: "6M"
        },
        "3Y": {
            id: 37,
            value: 3,
            label: "3 year",
            labelShort: "Y",
            resolution: "3Y",
            timeInterval: "3Y",
            timeFrame: "MF",
            historyPeriod: 2700,
            offset: 21 * 1440,
            baseFrame: Types.BASE_CHART_FRAME_HOUR,
            duration: 3 * 365 * 24 * 3600,
            type: "interval",
            isVisible: true
        },
    });

    /**
     * StockChart Types
     */

    static CHART_TYPES = new Map({
        "CANDLES": {
            id: "CANDLES",
            image: "CANDLES_CHART",
            label: "Candles",
            hint:"The candlestick chart is the most popular chart type because it most clearly shows the difference between the opening and closing prices of an instrument."
        },
        "OHLC": {
            id: "OHLC",
            image: "OHLC_CHART",
            label: "Bars",
            hint:"The bar charts allows you to get a better understanding of the asset price action, providing  data on the movement in the instrument price during the day"
        },
        "LINE": {
            id: "LINE",
            image: "LINE_CHART",
            label: "Line",
            hint:"Displays the dynamics of price movement as a line."
        },
        "AREA": {
            id: "AREA",
            image: "AREA_CHART",
            label: "Area",
            hint:"Displays the dynamics of price movement as an area."
        },
        "HEIKIN_ASHI": {
            id: "HEIKIN_ASHI",
            image: "HAIKIN_ASHI_CHART",
            label: "Heikin Ashi",
            hint:"The Heikin-Ashi chart makes it easier to spot trends and reversals."
        }
    },);

    static MAGNET_MODES = {
        "OFF": "off",
        "WEAK": "weak"
    };

    /**
     * StockChart draw lines
     */

    static CHART_DRAW_LINES = new Map({
        'ARROWLINE': {
            type: 'ARROWLINE',
            label: 'Arrow line',
            element: 'TRENDLINE',
            icon: 'I_LINES_ARROW',
        },
        'RAY': {
            type: 'RAY',
            label: 'Ray',
            element: 'TRENDLINE',
            icon: 'I_LINES_RAY',
        },
        'LINE': {
            type: 'LINE',
            label: 'Trendline',
            element: 'TRENDLINE',
            icon: 'I_LINES',
        },
        'XLINE': {
            type: 'XLINE',
            label: 'Extended Trend Line',
            element: 'TRENDLINE',
            icon: 'I_LINES',
        },
        'HORIZONTALLINE': {
            type: 'HORIZONTALLINE',
            label: 'Horizontal line',
            element: 'SIMPLELINE',
            icon: 'I_LINES_HORIZONTAL_LINE',
        },
        'VERTICALLINE': {
            type: 'VERTICALLINE',
            label: 'Vertical line',
            element: 'SIMPLELINE',
            icon: 'I_LINES_VERTICAL_LINE',
        },
        'HORIZONTALRAY': {
            type: 'HORIZONTALRAY',
            label: 'Horizontal ray',
            element: 'SIMPLELINE',
            icon: 'I_LINES_HORIZONTAL_RAY'
        },
        'TRENDANGEL': {
            type: 'TRENDANGEL',
            label: 'Trend angle',
            element: 'TRENDANGEL',
            icon: 'I_LINES_TREND_ANGLE',
        },
        'EQUIDISTANT_CHANNEL': {
            type: 'EQUIDISTANT_CHANNEL',
            label: 'Equidistant channel',
            element: 'EQUIDISTANT_CHANNEL',
            icon: 'I_LINES_STRAIGHT',
        },
        'STANDARD_DEVIATION_CHANNEL': {
            type: 'STANDARD_DEVIATION_CHANNEL',
            label: 'Standard deviation',
            element: 'STANDARD_DEVIATION_CHANNEL',
            icon: 'I_FIBONACCI_REGRESSION_TREND',
        },
        'PITCHFORK': {
            type: 'PITCHFORK',
            label: 'Andrews pitchfork',
            element: 'PITCHFORK',
            icon: 'I_FIBNACCI_PITCHFORK',
        },
        'FIBONACCI_TIME_ZONE': {
            type: 'FIBONACCI_TIME_ZONE',
            label: 'Fibonacci time zones',
            element: 'FIBONACCI_TIME_ZONE',
            icon: 'I_FIBONACCI_FIBONACCI_TIME_PERIODS',
        },
        'FIBONACCI_RETRACEMENT': {
            type: 'FIBONACCI_RETRACEMENT',
            label: 'Fibonacci retracement levels',
            element: 'FIBONACCI_RETRACEMENT',
            icon: 'I_FIBONACCI_FIBONACCI_RETRACEMENT',
        },
        'FIBONACCI_CHANNEL': {
            type: 'FIBONACCI_CHANNEL',
            label: 'Fibonacci channel',
            element: 'FIBONACCI_CHANNEL',
            icon: 'I_FIBONACCI_FIBONACCI_CHANNELS',
        },
        'TRENDBASE_FIBONACCI_EXTENSION': {
            type: 'TRENDBASE_FIBONACCI_EXTENSION',
            label: 'Trendbase fibonacci extension',
            element: 'TRENDBASE_FIBONACCI_EXTENSION',
            icon: 'I_FIBONACCI',
        },
        'GANN_FAN': { // END added to centration on this
            type: 'GANN_FAN',
            label: 'Gann Fan',
            element: 'GANN_FAN',
            icon: 'I_FIBONACCI_FAN_GANN',
        },
        'RECTANGLE': {
            type: 'RECTANGLE',
            label: 'Rectangle',
            element: 'RECTANGLE',
            icon: 'I_SHAPES_RECTANGLE',
        },
        'ROTATED_RECTANGLE': {
            type: 'ROTATED_RECTANGLE',
            label: 'Rotated rectangle',
            element: 'ROTATED_RECTANGLE',
            icon: 'I_SHAPES_ROTATE_RECTANGLE',
        },
        'ROTATED_ELLIPSE': {
            type: 'ROTATED_ELLIPSE',
            label: 'Rotated ellipse',
            element: 'ROTATED_ELLIPSE',
            icon: 'I_SHAPES_ROTATE_ELLIPSE',
        },
        'ROTATED_TRIANGLE': {
            type: 'ROTATED_TRIANGLE',
            label: 'Rotated triangle',
            element: 'ROTATED_TRIANGLE',
            icon: 'I_SHAPES_ROTATE_TRIANGLE',
        },
        'CURVE_LINE': {
            type: 'CURVE_LINE',
            label: 'Curve',
            element: 'CURVE_LINE',
            icon: 'I_LINES_CURVE',
        },
        'ARROW_MARK_UP': {
            type: 'ARROW_MARK_UP',
            label: 'Arrow up',
            element: 'ARROW_MARK_UP',
            icon: 'I_NOTES_ARROW_UP',
        },
        'ARROW_MARK_DOWN': {
            type: 'ARROW_MARK_DOWN',
            label: 'Arrow down',
            element: 'ARROW_MARK_DOWN',
            icon: 'I_NOTES_ARROW_DOWN',
        },
        'ARROW_MARK_FLAG': {
            type: 'ARROW_MARK_FLAG',
            label: 'Flag mark',
            element: 'ARROW_MARK_FLAG',
            icon: 'I_NOTES_FLAG',
        },
        'INTERACTIVE_BRUSH': {
            type: 'INTERACTIVE_BRUSH',
            label: 'Interactive brush',
            element: 'INTERACTIVE_BRUSH',
            icon: 'I_BRUSH1',
        },
        'TEXT_MARK': {
            type: 'TEXT_MARK',
            label: 'Text mark',
            element: 'TEXT_MARK',
            icon: 'I_NOTES_TEXT',
        }

    });

    /**
     *  Drawing object map
     */

    static DRAWING_OBJECT_MAP = {
        SIMPLELINE: 'trends',
        TRENDLINE: 'trends',
        TRENDANGEL: 'trends',
        PITCHFORK: 'trends',
        RECTANGLE: 'trends',
        ARROW_MARK_UP: 'trends',
        ARROW_MARK_DOWN: 'trends',
        ARROW_MARK_FLAG: 'trends',
        TEXT_MARK: 'trends',
        EQUIDISTANT_CHANNEL: 'channels',
        STANDARD_DEVIATION_CHANNEL: 'channels',
        FIBONACCI_CHANNEL: 'channels',
        FIBONACCI_RETRACEMENT: 'retracements',
        TRENDBASE_FIBONACCI_EXTENSION: 'channels',
        CURVE_LINE: 'curves',
        INTERACTIVE_BRUSH: 'curves',
        ROTATED_ELLIPSE: 'ellipses',
        ROTATED_TRIANGLE: 'triangles',
        GANN_FAN: 'fans',
        FIBONACCI_TIME_ZONE: 'timeZones',
        ROTATED_RECTANGLE: 'rectangles'
    };

    /**
     * Drawing trends
     */

    static DRAWING_TRENDS = {
        TRENDLINE : 'TRENDLINE',
        SIMPLELINE: 'SIMPLELINE',
        TRENDANGEL: 'TRENDANGEL',
        EQUIDISTANT_CHANNEL: 'EQUIDISTANT_CHANNEL',
        STANDARD_DEVIATION_CHANNEL: 'STANDARD_DEVIATION_CHANNEL',
        PITCHFORK: 'PITCHFORK',
        FIBONACCI_TIME_ZONE: 'FIBONACCI_TIME_ZONE',
        FIBONACCI_RETRACEMENT: 'FIBONACCI_RETRACEMENT',
        FIBONACCI_CHANNEL: 'FIBONACCI_CHANNEL',
        TRENDBASE_FIBONACCI_EXTENSION: 'TRENDBASE_FIBONACCI_EXTENSION',
        GANN_FAN: 'GANN_FAN',
        RECTANGLE: 'RECTANGLE',
        ROTATED_RECTANGLE: 'ROTATED_RECTANGLE',
        ROTATED_ELLIPSE: 'ROTATED_ELLIPSE',
        ROTATED_TRIANGLE: 'ROTATED_TRIANGLE',
        CURVE_LINE: 'CURVE_LINE',
        ARROW_MARK_UP: 'ARROW_MARK_UP',
        ARROW_MARK_DOWN: 'ARROW_MARK_DOWN',
        ARROW_MARK_FLAG: 'ARROW_MARK_FLAG',
        INTERACTIVE_BRUSH: 'INTERACTIVE_BRUSH',
        TEXT_MARK: 'TEXT_MARK'
    };

    /**
     * StockChart indicators groups
     */

    static INDICATOR_GROUPS = new Map({
        "MOVING_AVERAGE_GROUP": {
            id: "MOVING_AVERAGE_GROUP",
            label: "Moving Average",
            alias: "ma",
            icon: 'I_INDICATOR_MA_GROUP',
            isVisible: true
        },
        "VOLUMES_GROUP": {
            id: "VOLUMES_GROUP",
            label: "Volumes",
            alias: "volumes",
            icon: 'I_INDICATOR_VOLUMES_GROUP',
            isVisible: true
        },
        "TREND_AND_CHANEL_GROUP": {
            id: "TREND_AND_CHANEL_GROUP",
            label: "Trend indicators",
            alias: "trendAndChannel",
            icon: 'I_INDICATOR_TRADE_AND_CHANEL_GROUP',
            isVisible: true
        },
        "OSCILLATOR_GROUP": {
            id: "OSCILLATOR_GROUP",
            label: "Oscillators",
            alias: "oscillator",
            icon: 'I_INDICATOR_OSCILLATOR_GROUP',
            isVisible: true
        },
        "BILL_WILLIAMS_GROUP": {
            id: "BILL_WILLIAMS_GROUP",
            label: "Williams indicators",
            alias: "billWilliams",
            icon: 'I_INDICATOR_BW_GROUP',
            isVisible: true
        },
        "VOLATILITY_GROUP": {
            id: "VOLATILITY_GROUP",
            label: "Volatility indicators",
            alias: "volatility",
            icon: 'I_INDICATOR_VOLATILITY_GROUP',
            isVisible: true
        }
    });

    /**
     * Specific drawing objects
     */

    static SPECIFIC_DRAWING_OBJECT_TYPES = {
        ARROW_UP : 'ARROW_MARK_UP',
        ARROW_DOWN: 'ARROW_MARK_DOWN',
        FLAG_MARK: 'ARROW_MARK_FLAG',
        INTERACTIVE_BRUSH: 'INTERACTIVE_BRUSH'
    };

    /**
     * StockChart indicators groups
     */

    static MOVING_AVERAGE_GROUP = "MOVING_AVERAGE_GROUP";
    static VOLUMES_GROUP = "VOLUMES_GROUP";
    static TREND_AND_CHANEL_GROUP = "TREND_AND_CHANEL_GROUP";
    static OSCILLATOR_GROUP = "OSCILLATOR_GROUP";
    static BILL_WILLIAMS_GROUP = "BILL_WILLIAMS_GROUP";
    static VOLATILITY_GROUP = "VOLATILITY_GROUP";

    /**
     * StockChart indicators positions
     */

    static INTERNAL_INDICATOR = 'internal';
    static EXTERNAL_INDICATOR = 'external';

    /**
     * StockChart indicators types
     */

    static INDICATOR_ADX = 'ADX';
    static INDICATOR_OBV = 'OBV';
    static INDICATOR_MFI = 'MFI';
    static INDICATOR_A_D = 'A_D';
    static INDICATOR_VOLUME = 'VOLUME';
    static INDICATOR_VOLUME_AVG = 'VOLUME_AVG';
    static INDICATOR_BB = 'BB';
    static INDICATOR_VIDYA = 'VIDYA';
    static INDICATOR_ENVELOPES = 'ENVELOPES';
    static INDICATOR_TEMA = 'TEMA';
    static INDICATOR_STANDARD_DEVIATION = 'STANDARD_DEVIATION';
    static INDICATOR_ADMIW = 'ADMIW';
    static INDICATOR_SAR = 'SAR';
    static INDICATOR_IC = 'IC';
    static INDICATOR_CCI = 'CCI';
    static INDICATOR_CHO = 'CHO';
    static INDICATOR_DEMARKER = 'DEMARKER';
    static INDICATOR_MACD = 'MACD';
    static INDICATOR_MOM = 'MOM';
    static INDICATOR_RSI = 'RSI';
    static INDICATOR_RVI = 'RVI';
    static INDICATOR_STO = 'STO';
    static INDICATOR_BEARPOW = 'BEARPOW';
    static INDICATOR_BULLPOW = 'BULLPOW';
    static INDICATOR_AC = 'AC';
    static INDICATOR_BWMFI = 'BWMFI';
    static INDICATOR_WPR = 'WPR';
    static INDICATOR_ATR = 'ATR';
    static INDICATOR_MAMA = 'MAMA';
    static INDICATOR_FORCE_INDEX = "ForceIndex"

    static REPORTS_ENDPOINTS = {
        exchangeHistory: 'ExchangeTransactionsTrader',
        transactionHistory: 'DepositWithdrawalHistory',
        accountHistory: 'positions',
        notifications: 'UserNotifications',
    };

    static DEFAULT_REPORTS_PRECISION = 6;

    static REPORTS_COLUMN_TYPE = {
        ALL: 'ALL',
        ONLY_TERMINAL: 'ONLY_TERMINAL',
        ONLY_DOCS: 'ONLY_DOCS'
    };

    static DOWNLOAD_REPORT_COLUMNS = {
        activityLog: [
            { label: 'Action ID', key: 'actionId' },
            { label: 'Trade ID', key: 'orderId' },
            { label: 'Date', key: 'date' },
            { label: 'Wallet', key: 'wallet' },
            { label: 'Event', key: 'action' },
        ],
        marginHistory: [
            { label: 'Trade №' , key: 'tradeNumber' },
            { label: 'Wallet №', key: 'walletNumber' },
            { label: 'Wallet currency', key: 'walletCurrency' },
            { label: "Asset", key: 'asset' },
            { label: 'Investment amount', key: 'investmentAmount' },
            { label: 'Investment currency', key: 'investmentCurrency' },
            { label: 'Trade size', key: 'tradeSize' },
            { label: 'Trade currency', key: 'tradeCurrency' },
            { label: 'Opening type', key: 'openType' },
            { label: 'Close Type', key: 'closeType' },
            { label: 'Open Time', key: 'openTime' },
            { label: 'Close Time', key: 'closeTime' },
            { label: 'Open Price', key: 'orderPrice' },
            { label: 'Close Price', key: 'closePrice' },
            { label: 'Financing', key: 'swap' },
            { label: 'Commission', key: 'commission' },
            { label: 'Commission currency', key: 'commissionCurrency' },
            { label: 'Gross Profit/Loss short', key: 'grossProfit' },
            { label: 'Gross Profit/Loss currency short', key: 'grossProfitCurrency' },
            { label: 'Net Profit/Loss short', key: 'netProfit' },
            { label: 'Net Profit/Loss currency short', key: 'netProfitCurrency' },
            { label: 'Profitability', key: 'profitability' },
            { label: 'Comments', key: 'comment' },
        ],
        exchangeHistory: [
            { label: 'Transaction №', key: 'transaction' },
            { label: 'Date', key: 'date' },
            { label: 'From wallet', key: 'spentCurrency' },
            { label: 'To wallet', key: 'receivedCurrency' },
            { label: 'Exchange rate', key: 'exchangeRate' },
            { label: 'Spent', key: 'spent' },
            { label: 'Commission', key: 'commission' },
            { label: 'Commission currency', key: 'commissionCurrency' },
            { label: 'Received including commission', key: 'received' },
        ],
        balanceHistory: [
            { label: 'Transaction №', key: 'transaction' },
            { label: 'Date', key: 'date' },
            { label: 'Wallet №', key: 'walletNumber' },
            { label: 'Wallet currency', key: 'currency' },
            { label: 'Operation type', key: 'type' },
            { label: 'Method', key: 'method' },
            { label: 'Requested amount', key: 'final' },
            { label: 'Commission', key: 'commission' },
            { label: 'Commission currency', key: 'commissionCurrency' },
            { label: 'Money amount', key: 'total' },
            { label: 'Status', key: 'status' },
            { label: 'Comments', key: 'comment' },
        ]
    };

    static DIRECTION_ROW = "row";
    static DIRECTION_COLUMN = "column";

    static INVESTING_TABS = [
        { tab: 0, title: "All strategies" },
        { tab: 1, title: "My subscriptions" },
        { tab: 2, title: "Manager area" }
    ];

    static SECURITY_WINDOW = {
        CHANGE_PASSWORD: "securityChangePassword",
        TWO_FA_GOOGLE: "securityTwoFactorGoogle",
        TWO_FA_EMAIL: "securityTwoFactorEmail",
        TWO_FA: "securityTwoFactor",
        ANTI_PHISHING_CODE: "securityAntiPhishing",
        API_KEY: "securityApiKey",
    };

    static sidePanelTabs = {
        TAB_MARKET: 'MARKET',
        TAB_FAVORITES: 'FAVORITES',
        TAB_NEWS: 'NEWS',
        TAB_ECONOMIC_CALENDAR: 'ECONOMIC_CALENDAR',
        TAB_TRADES: 'TRADES',
        TAB_PROFILE: 'PROFILE',
        TAB_POSITIONS: 'POSITIONS',
        TAB_ORDERS: 'ORDERS',
        TAB_HISTORY: 'HISTORY',
        TAB_ACTIVITY_LOG: 'ACTIVITY_LOG',
        TAB_SETTINGS: 'SETTINGS',
        TAB_INVESTING: 'INVESTING',
        TAB_NOTIFICATIONS: 'NOTIFICATIONS',
        TAB_LIFE_SUPPORT: 'LIFE_SUPPORT',
        TAB_WL_MAIN_PAGE: 'WL_MAIN_PAGE',
        TAB_REFERRAL_CABINET: 'REFERRAL_CABINET',
        TAB_LOGOUT: 'LOGOUT',
    }
    
    static CONFIRMATION_TYPE_BUTTON = 'BUTTON';
    static CONFIRMATION_TYPE_CHECKBOX = "CHECKBOX";

    static KEY_CODES = {
        BACKSPACE: 8,
        ESC: 27,
        CTRL: 17,
        ARROW_LEFT: 37,
        ARROW_RIGHT: 39,
        DELETE: 46,
    };
}