import AppConfig from '../AppConfig';
import Api from './api';
import Types from './types';
import math from '../utils/math';

const { getCustomLanguage } = require(`../targets/${process.env.REACT_APP_TARGET_NAME}/lang/index.js`);

export default class Lang {
    _translateSource = {};
    _api = null;
    _lang = AppConfig.defines.APP_LANG;

    get name() {
        return this._lang;
    }

    _dateLangReplace = {
        "ar": "en"
    };

    _intl = {
        dateFormatter: { format: f => f},
        dateTimeFormatter: { format: f => f},
        shortTimeFormatter: { format: f => f},
        timeFormatter: { format: f => f},
        numberFormatter: { format: f => f.toString()}
    };

    _initIntl() {
        const lang = this._dateLangReplace[this._lang] || this._lang;
        let hour12 = false;
        if (this._lang === 'en') {
            hour12 = true;
        }

        // WARNING for ie need not use Object.assign and js object destructuring. Strange behavior in timeFormatter!
        this._intl = {
            numberFormatter: new Intl.NumberFormat('ru', {useGrouping: true, maximumFractionDigits: 19}), // fixed format
            dateFormatter: new Intl.DateTimeFormat(lang, {
                hour12
            }),
            dateTimeFormatter: new Intl.DateTimeFormat(lang, {
                hour12,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }),
            timeFormatter: new Intl.DateTimeFormat(lang, {
                hour12,
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }),
            shortTimeFormatter: new Intl.DateTimeFormat(lang, {
                hour12,
                hour: 'numeric',
                minute: 'numeric',
            })
        };
    };

    constructor(api) {
        if (!(api instanceof Api)) {
            throw new Error('api provider incompatible');
        }
        this._api = api;
        this.usedKeys = {};
        this.languages = Object.keys(Types.LANGUAGES);
    }

    getTranslationFiles() {
        const files = this.languages.map(lang => 
            this._api.get(AppConfig.getServerAddress(AppConfig.defines.LANG_URL) + lang + ".json", { options: {timeout: 10000}})
        );
        const promise = new Promise((resolve) => {
            Promise.all(files).then(resp => {
                const files = resp.map((file, index) => ({lang: this.languages[index], data: file.data})).filter(obj => obj.data);
                resolve(files);
            })
        });

        return promise;
    }
    
    getTranslationsData() {
        return this.getTranslationFiles().then((files) => {
            const langsCount = files.length;
            const data = {};

            for (let i = 0; i < langsCount; i++) {
                const translations = files[i].data;
                const lang = files[i].lang;

                Object.keys(translations).forEach(t => {
                    data[t] = {
                        exist: ((data[t] && data[t].exist) || []).concat(lang),
                        missing: ((data[t] && data[t].missing) || this.languages).filter(l => l !== lang)
                    };
                    data[t] && data[t].exist.length === langsCount && delete data[t];
                });
            }

            return data;
        })
    }

    getTranslationsUseInfo() {
        const source = this._translateSource;
        const usedKeysArray = Object.keys(this.usedKeys);
        const missingTranslations = usedKeysArray.filter(key => !source[key]);
        const unusedTranslations = Object.keys(source).filter(key => !this.usedKeys[key]);

        const data = {
            lang: this._lang,
            missingTranslations,
            unusedTranslations
        };

        return data;
    }

    init(lang) {
        if (typeof lang === "string") {
            lang = lang.toLowerCase();
        }

        return new Promise((resolve, reject) => {
            if ((this._lang === lang && Object.keys(this._translateSource).length > 0)
                || typeof lang === 'undefined'
            ) {
                this._initIntl();
                resolve();
                return;
            }
            this._api.get(AppConfig.getServerAddress(AppConfig.defines.LANG_URL) + lang + ".json",
                {
                    options: {timeout: 10000}
                })
                .then((response) => {
                    this._lang = lang;
                    this._translateSource = Object.assign(response.data, getCustomLanguage(lang)) || {};
                    this.usedKeys = {};

                    this._initIntl();
                    resolve();
                })
                .catch((error) => {
                    this._initIntl();
                    reject(error);
                });
        });
    }


    /**
     *
     * @param text
     * @param data {object|null} объект для подстановки значение в "маркеры" перевода
     * @returns {*}
     */
    t(text, data = null) {
        this.usedKeys[text] = true;

        if (typeof(this._translateSource[text]) !== "undefined") {
            text = this._translateSource[text];
        }

        if (data === null) {
            return text;
        }

        Object.keys(data).forEach((key) => {
            text = text.replace(new RegExp('{'+key+'}','g'), data[key]);
        });

        return text;
    }

    number(val, dec = 2, decFixed = false) {
        val = +val;
        val = isNaN(val) ? 0 : val;
        val = val.toFixed(dec);

        if (dec && decFixed) {
            const parts = val.split('.');
            let integer = parts[0];
            if (integer !== "-0") {
                integer = this._intl.numberFormatter.format(+integer);
            }

            return `${integer}.${parts[1]}`;
        }

        return this._intl.numberFormatter.format(+val).replace(',', '.');
    }

    numberNoFix(val) {
        val = +val;
        val = isNaN(val) ? 0 : val;

        return this._intl.numberFormatter.format(+val).replace(',', '.');
    }

    money(amount, currency) {
        const precision = AppConfig.getPaymentCurrencyPrecision(currency);

        return this.number(amount, precision, true);
    }

    moneyFloor(amount, currency) {
        const precision = AppConfig.getPaymentCurrencyPrecision(currency);

        return this.numberNoFix(math.floorToPrecision(+amount, precision));
    }

    moneyCeil(amount, currency) {
        const precision = AppConfig.getPaymentCurrencyPrecision(currency);

        return this.numberNoFix(math.ceilToPrecision(+amount, precision));
    }

    ceilFloatNumber(val, dec = 2) {
        const pow = Math.pow(10, dec);

        return (Math.ceil(+(+val * pow).toFixed(10))) / pow;
    }

    /**
     * приведение число в норм. вид для вычислений
     */
    numberFromString(number, defNumber = 0) {
        number = +number.toString().replace(/[\D|.]/g, '');
        if (isNaN(number)) {
            return defNumber;
        }

        return number;
    }

    splitNumber(val, splitOperator = ' ') {
        splitOperator.toString();
        val += val.toString();
        const x = val.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + splitOperator + '$2');
        }
        return x1 + x2;
    }

    static shortNumberAbbr = [
        {symbol: '', size: 1000},
        {symbol: 'K', size: 1000000},
        {symbol: 'M', size: 1000000000},
        {symbol: 'B', size: 1000000000000},
    ];

    static shortByteAbbr = [
        {symbol: 'B', size: 1024},
        {symbol: 'KB', size: 1048576},
        {symbol: 'MB', size: 1073741824},
        {symbol: 'GB', size: 1099511627776},
    ];

    shortNumber(number, maxAccuracy = 3, byte = false) {
        const decPlaces = Math.pow(10, maxAccuracy);
        const shortNumberAbbr = byte ? Lang.shortByteAbbr : Lang.shortNumberAbbr
        const shortNumberAbbrLastIndex = shortNumberAbbr.length - 1;

        for (let i = 0; i <= shortNumberAbbrLastIndex; i++) {
            const shortAbbr = shortNumberAbbr[i];
            if (shortAbbr.size > Math.abs(number)) {
                if (i > 0) {
                    number = Math.round(number * decPlaces / shortNumberAbbr[i - 1].size) / decPlaces;
                }

                number += shortAbbr.symbol;
                break;
            }
        }

        return number.toString();
    }

    numberDiff(numer1, number2, accuracy) {
        const decPlaces = Math.pow(10, accuracy);

        return Math.ceil(Math.abs(numer1 * decPlaces - number2 * decPlaces));
    }

    date(val) {
        return this._intl.dateFormatter.format(val instanceof Date ? val : new Date(Lang.normalizeDate(val)));
    }

    dateTime(val) {
        return this._intl.dateTimeFormatter.format(val instanceof Date ? val : new Date(Lang.normalizeDate(val)));
    }

    time(val) {
        return this._intl.timeFormatter.format(val instanceof Date ? val : new Date(Lang.normalizeDate(val)));
    }

    shortTime(val) {
        return this._intl.shortTimeFormatter.format(val instanceof Date ? val : new Date(Lang.normalizeDate(val)));
    }

    /**
     *
     * @param number {number|string}
     * @param currency {string}
     * @param formatNumber {boolean}
     */
    currency(number, currency, formatNumber = false) {
        if (currency.length > 3) {
            currency = currency.slice(0, 3);
        }

        if (formatNumber) {
            return new Intl.NumberFormat(this._lang, {
                style: 'currency',
                currency: currency.toUpperCase(),
            }).format(number);
        }

        const formattedNumber = new Intl.NumberFormat(this._lang, {
            style: 'currency',
            currency: currency.toUpperCase(),
            minimumFractionDigits: 0
        }).format(0);

        // if currency has not symbol
        if (/^\w{3}/.test(formattedNumber)) {
            return formattedNumber.replace('0', ` ${number}`);
        }

        return formattedNumber.replace('0', number);
    }

    static normalizeDate(value) {
        if (typeof value === 'string' && value.indexOf('GMT') === -1) {
            value += ' GMT'; // time in UTC format
        }

        return value;
    }
}