/**
 * Created by ebondarev
 */
import { Record, List, Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';

import Types from '../../classes/types';
import { store } from '../../store';
import { logger } from '../../services';
import * as actionTypes from '../../actions/trade/chart';
import * as tickersActions from '../../actions/trade/tickers';
import * as trackingActions from '../../actions/tracking/tracking';
import * as userActions from '../../actions/user/user';
import * as accountsActions from '../../actions/trade/accounts';
import { TmxDataFeed, TmxDataFeedSymbol } from '../../classes/tmx-data-feed';
import * as transportTradeTypes from '../../actions/transports/trade';
import Register from '../../classes/register';
import { ChartTabModel } from '../../models/chart-tab';
import { IndicatorModel } from '../../models/indicator';
import { isMobile } from '../../utils/platform';


import { AppConfig } from '../../AppConfig';

// init tabs list
export const STORAGE_SAVED_TABS = 'chart_tabs';
export const STORAGE_TABS_INDICATORS = 'chart_tabs_indicators';
export const STORAGE_CHART_SETTINGS = 'chart_settings';

const initialChartSettings = {
    showFullWidthPriceLine: false,
    showAsk: false,
    showBid: true,
    separationBetweenAskBid: false,
    showPendingOrders: false,
    showLevelsProfitLoss: false,
    showVolume: false,
    showGrid: true,
    showScalePotentialProfit: true,
    showOrders: true,
    showPriceIndicator: false,
    chartType: 'AREA',
    ...AppConfig.stockChart.chartSettings,
};

const storeChartSettings = Register.get(STORAGE_CHART_SETTINGS);

function createTmxDataFeed() {
    const tmxDataFeed = new TmxDataFeed();
    tmxDataFeed.onGetBars = ({ reqId, symbolId, periodM, periodB, from, to }) => {
        transportTradeTypes.doInit().then(() => {
            store.dispatch(actionTypes.doQuoteHistory({ reqId, symbolId, periodM, periodB, from, to }));
        }).catch((e) => {
            logger.warn('Did not get quote history, no connecting');
        });
    };

    return tmxDataFeed;
}

class ChartStore extends Record({
    chartFrameRequest: null,
    tmxDataFeed: createTmxDataFeed(),
    lastUpdateAt: Date.now(),
    tabs: new List(),
    activeTab: null,
    isReadyTabs: false,
    stockCharts: new Map(),
    chartDatas: new Map(),
    indicators: new Map(),
    indicatorTemplates: new window.Map(Register.get(Types.INDICATOR_TEMPLATES_STORE_NAME, [])),
    chartSettings: (() => {
        const settings = storeChartSettings ? storeChartSettings : initialChartSettings;
        if (isMobile) {
            settings.showVolume = false;
        }
        return settings;
    })(),
    isSLTPCompleted: false,
    activeChartElementByClick: false,
    isChartElementHover: false,
    chartActiveElementId: null,
    showChartTools: false,
}) {
}

const initialState = new ChartStore();

function getSavedTabsList(storageTabsKey, tickersList) {
    const chartTabsList = new List(getSavedTabs(storageTabsKey)
        .filter(chartTabData => !!tickersList.get(chartTabData.symbolId))
        .map(chartTabData => {
            const chartTab = (new ChartTabModel()).copyFrom(chartTabData);
            chartTab.synced = true;
            return chartTab;
        })
    );
    return chartTabsList;
}

export function getStorageTabsKey() {
    return STORAGE_SAVED_TABS;
}

function getSavedTabs(storageTabsKey) {
    return Register.get(storageTabsKey, []);
}

function getStorageTabsIndicators(tabsList) {
    const storageIndicators = Register.get(STORAGE_TABS_INDICATORS, []);
    const indicators = [];

    storageIndicators.forEach((indicatorsList) => {
        indicators.push(
            new Map(indicatorsList).map(
                (indicator) => new IndicatorModel(indicator, indicator._key)
            )
        );
    });

    const newIndicators = new Map(
        tabsList.toMap()
    ).mapEntries(([key, tab], index) => [
        tab.key,
        indicators[index] || new Map(),
    ]);
    return newIndicators;
}

function saveTabs(tabsList, storageTabsKey) {
    window.setTimeout(() => {
        Register.set(storageTabsKey, tabsList.toArray());
    });
}

function saveIndicators(indicatorsList) {
    window.setTimeout(() => {
        Register.set(STORAGE_TABS_INDICATORS, indicatorsList.toArray());
    });
}

function saveChartSettings(settings) {
    window.setTimeout(() => Register.set(STORAGE_CHART_SETTINGS, settings));
}

export default function charts(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TOGGLE_CHART_TOOLS:
            const isShowing = action.payload;
            return state.set('showChartTools', isShowing);

        case tickersActions.UPDATE_QUOTE:
            state.tmxDataFeed.updateQuote(action.payload);
            return state;

        case tickersActions.UPDATE_QUOTES:
        case transportTradeTypes.UPDATE_DATA:
            const updatedData = action.type === transportTradeTypes.UPDATE_DATA
                ? action.payload.quotes : action.payload;

            if (!updatedData) {
                return state;
            }

            for (let symbolId in updatedData) {
                state.tmxDataFeed.updateQuote(updatedData[symbolId]);
            }

            return state;

        case tickersActions.FETCHED: {
            const tickersList = store.getState().trade.tickers.list;
            const chartTabsList = getSavedTabsList(getStorageTabsKey(), tickersList);
            const storageIndicators = getStorageTabsIndicators(chartTabsList);
            const activeTab = state.activeTab ? chartTabsList.find((tab) => tab._key === state.activeTab._key) : chartTabsList.first() || null;
            const stateIndicators = new Map(chartTabsList.toMap()).mapEntries(([key, tab]) => [tab.key, state.indicators.get(tab.key) || new Map()]);
            const indicators = stateIndicators.merge(storageIndicators);
            const chartDatas = new Map(chartTabsList.toMap()).mapEntries(([key, tab]) => [tab.key, state.chartDatas.get(tab.key) || null]);
            const stockCharts = new Map(chartTabsList.toMap()).mapEntries(([key, tab]) => [tab.key, state.stockCharts.get(tab.key) || new window.Map()]);

            tickersList.forEach((ticker) => {
                state.tmxDataFeed.addSymbol(TmxDataFeedSymbol.createFromTicker(ticker));
            });

            return state.merge({
                tabs: chartTabsList,
                activeTab: activeTab,
                chartDatas,
                stockCharts,
                indicators,
                lastUpdateAt: Date.now(),
            });
        }

        case trackingActions.GET_STORE:
            return (action.data.charts = (action.payload) ? state : initialState);

        case trackingActions.SET_STORE: {
            if (action.payload && action.payload.charts) {
                const tickersStore = store.getState().trade.tickers.list;

                const chartData = action.payload.charts;
                const symbolsIds = Object.keys(chartData.tmxDataFeed._symbols);
                chartData.tmxDataFeed = createTmxDataFeed();

                chartData.tabs = new List(chartData.tabs.map(chartTabData => {
                    const chartTab = (new ChartTabModel()).copyFrom(chartTabData);
                    chartTab.synced = false;
                    return chartTab;
                }));
                if (chartData.activeTab)
                    chartData.activeTab = chartData.tabs.find(tab => tab._key === chartData.activeTab._key);

                //saveTabs(chartData.tabs, getStorageTabsKey());
                //saveChartSettings(chartData.chartSettings);

                chartData.stockCharts = new Map(chartData.stockCharts).map(stockChart => new window.Map(stockChart));
                chartData.chartDatas = new Map(chartData.chartDatas).map(datas => datas && datas.map(data => ({ ...data, date: new Date(data.date)})));
                chartData.indicators = new Map(chartData.indicators).map(chartIndicators => new Map(chartIndicators).map(indicator => new IndicatorModel(indicator, indicator._key)));
                chartData.indicatorTemplates = new window.Map(chartData.indicatorTemplates);

                const newState = state.merge(chartData).update('chartSettings', () => chartData.chartSettings); // Map received object to state

                tickersStore.forEach((ticker) => {
                    if (symbolsIds.includes(ticker.SymbolId)) newState.tmxDataFeed.addSymbol(TmxDataFeedSymbol.createFromTicker(ticker));
                });

                return newState;
            }

            return state;
        }

        case trackingActions.FETCH_ACTION_PAYLOAD: {
            const actionData = action.payload;
            if (actionData.Action === actionTypes.ADD_TAB) {
                actionData.ActionPayload.tabModel = new ChartTabModel().copyFrom(actionData.ActionPayload.tabModel);
            }
            else if (actionData.Action === actionTypes.CHANGE_ACTIVE_TAB || actionData.Action === actionTypes.REMOVE_TAB) {
                actionData.ActionPayload = state.tabs.find(tab => tab._key === actionData.ActionPayload._key);
            }
            else if (actionData.Action === actionTypes.UPDATE_TABS) {
                actionData.ActionPayload = actionData.ActionPayload.map(tab => { return (new ChartTabModel()).copyFrom(tab) });
            }
            else if (actionData.Action === actionTypes.CHANGE_CHART_FRAME || actionData.Action === actionTypes.CHANGE_CHART_TYPE ||
                actionData.Action === actionTypes.CHANGE_CHART_AUTO_INTERVAL_SWITCH) {
                actionData.ActionPayload.tab = state.tabs.find(tab => tab._key === actionData.ActionPayload.tab._key);
            } else if (actionData.Action === actionTypes.SET_STOCK_CHARTS) {
                actionData.ActionPayload.stockCharts = new window.Map(actionData.ActionPayload.stockCharts);
            } else if (actionData.Action === actionTypes.CHART_DATA_UPDATE && actionData.ActionPayload.data) {
                actionData.ActionPayload.data = actionData.ActionPayload.data.map(d => ({
                    ...d,
                    date: new Date(d.date),
                }));
            } else if (actionData.Action === actionTypes.CHANGE_CHART_IS_MONOCHROME) {
                actionData.ActionPayload.tab = new ChartTabModel().copyFrom(actionData.ActionPayload.tab);
            } else if ([actionTypes.INDICATOR_ADD, actionTypes.INDICATOR_REPLACE, actionTypes.INDICATOR_UPDATE].includes(actionData.Action)) {
                const { indicator } = actionData.ActionPayload;
                actionData.ActionPayload.indicator = new IndicatorModel(indicator, indicator._key);
            } else if (actionData.Action === actionTypes.ALL_INDICATORS_REPLACE) {
                actionData.ActionPayload.indicators = new Map(actionData.ActionPayload.indicators).map(i => new IndicatorModel(i, i._key))
            }

            return state;
        }

        case actionTypes.CHANGE_ACTIVE_TAB:
            const changeActiveTab = action.payload;

            return state.merge({
                activeTab: changeActiveTab,
                activeChartElementByClick: false,
                isChartElementHover: false,
                chartActiveElementId: null,
            });

        case actionTypes.RESET_SLTP_DRAG_COMPONENT:
            return state.merge({
                isSLTPCompleted: action.payload
            });

        case actionTypes.CHANGE_CHART_SETTINGS:
            const changeChartSettings = action.payload;
            saveChartSettings(changeChartSettings);

            return state.set('chartSettings', changeChartSettings);

        case actionTypes.CHANGE_CHART_FRAME_REQUEST:
            {
                return state.merge({
                    chartFrameRequest: { frame: action.payload.frame, tabKey: action.payload.tabKey }
                });
            }

        case actionTypes.SET_TAB_SYNC_STATE: {
            let tab = state.tabs.find((tab) => tab.key === action.payload.tab.key);
            if (tab) tab.synced = action.payload.tab.synced = action.payload.syncState;
            return state;
        }

        case actionTypes.CANCEL_CHART_FRAME_REQUEST: {
            return state.merge({
                chartFrameRequest: null
            });
        }

        case actionTypes.CHANGE_CHART_FRAME:
        case actionTypes.CHANGE_CHART_TYPE:
        case actionTypes.CHANGE_CHART_AUTO_INTERVAL_SWITCH:
        case actionTypes.CHANGE_CHART_AUTO_UPDATE_FRAME_BY_ZOOM: {
            const tabs = cloneDeep(state.tabs);
            let tab = tabs.find((tab) => tab.key === action.payload.tab.key);
            if (typeof tab === "undefined") {
                tab = action.payload;
                tab.frame = state.chartSettings.frame;
            } else {
                if (action.type === actionTypes.CHANGE_CHART_FRAME) {
                    tab.frame = action.payload.frame;
                    if (action.payload.frame === "5S")
                        tab.chartType = "AREA"
                } else {
                    if (tab.frame !== "5S")
                        tab.chartType = action.payload.chartType;
                }
            }

            if (!state.activeTab || tab.key !== state.activeTab.key) {
                return state
            }

            if (action.type === actionTypes.CHANGE_CHART_AUTO_INTERVAL_SWITCH) {
                tab.chartType = action.payload.tab.chartType;
                tab.isAutoTimeFrame = action.payload.isAutoSwitch;
            }

            if (action.type === actionTypes.CHANGE_CHART_AUTO_UPDATE_FRAME_BY_ZOOM) {
                tab.chartType = action.payload.tab.chartType;
                tab.isAutoUpdateFrameByZoom = action.payload.isAutoUpdateFrameByZoom;
            }

            saveTabs(tabs, getStorageTabsKey());
            const nextChartFrameRequest = (action.payload.isByRequest) ? null : cloneDeep(state.chartFrameRequest);

            return state.merge({
                tabs: tabs,
                activeTab: tab,
                chartFrameRequest: nextChartFrameRequest,
            });
        }

        case actionTypes.CHANGE_CHART_IS_MONOCHROME: {
            const tabs = cloneDeep(state.tabs);
            let tab = tabs.find((tab) => tab.key === action.payload.tab.key);

            tab.isMonochrome = action.payload.isMonochrome;

            saveTabs(tabs, getStorageTabsKey());
            const nextChartFrameRequest = (action.payload.isByRequest) ? null : cloneDeep(state.chartFrameRequest);

            return state.merge({
                tabs: tabs,
                activeTab: tab,
                chartFrameRequest: nextChartFrameRequest,
            });
        }

        case actionTypes.CHANGE_SHOW_DEFAULT_INDICATORS: {
            const newTabs = state.tabs.map(tab => {
                if (tab.key !== action.payload.tabKey) return tab;

                return new ChartTabModel().copyFrom({ ...tab, showDefaultIndicators: action.payload.showDefaultIndicators});
            });
            let activeTab = state.activeTab;

            saveTabs(newTabs, getStorageTabsKey());

            if (activeTab.key === action.payload.tabKey) {
                activeTab = newTabs.find(tab => tab.key === activeTab.key);
            }

            return state.merge({
                tabs: newTabs,
                activeTab,
            });
        }

        case actionTypes.RESET_CHART_SETTINGS:
            Register.remove(STORAGE_CHART_SETTINGS);
            return state.set('chartSettings', initialChartSettings);

        case actionTypes.ADD_TAB:
            const { tabModel: addedTab, frame } = action.payload;
            const maxTabCount = AppConfig.stockChart.maxTabCount;
            addedTab.frame = frame ? frame : state.chartSettings.frame;
            addedTab.chartType = state.chartSettings.chartType;
            let addedTabsList;

            if (state.tabs.size === maxTabCount) {
                const popTabsList = state.tabs.shift();
                addedTabsList = popTabsList.push(addedTab);
            } else
                addedTabsList = state.tabs.push(addedTab);

            const newIndicators = state.indicators.set(addedTab.key, new Map());

            saveTabs(addedTabsList, getStorageTabsKey());
            saveIndicators(newIndicators);

            return state.merge({
                tabs: addedTabsList,
                stockCharts: state.stockCharts.set(addedTab.key, new window.Map()),
                chartDatas: state.chartDatas.set(addedTab.key, null),
                indicators: newIndicators,
                activeTab: addedTab
            });

        case actionTypes.REMOVE_TAB:
            const needRemoveTab = action.payload;
            let listAfterRemoveTab = state.tabs;
            let activeTabAfterRemove = null;
            let removedIndex = 0;
            const afterRemoveTabsKey = state.tabs.findKey((tab) => {
                return tab.key === needRemoveTab.key;
            });

            if (isFinite(afterRemoveTabsKey)) {
                removedIndex = afterRemoveTabsKey - 1;
                listAfterRemoveTab = listAfterRemoveTab.delete(afterRemoveTabsKey);
            }

            if (needRemoveTab === state.activeTab) {
                const afterRemoveKey = listAfterRemoveTab.skip(removedIndex).findKey((tab) => tab.isShown);
                const beforeRemoveKey = listAfterRemoveTab.take(removedIndex).findLastKey((tab) => tab.isShown);

                if (afterRemoveKey >= 0) {
                    activeTabAfterRemove = listAfterRemoveTab.get(removedIndex + afterRemoveKey);
                } else if (beforeRemoveKey >= 0) {
                    activeTabAfterRemove = listAfterRemoveTab.get(beforeRemoveKey);
                }

            } else if (state.activeTab) {
                activeTabAfterRemove = state.activeTab;
            }

            const hasActiveTabsAfterRemove = listAfterRemoveTab.some(tab => tab.isShown);
            if (!hasActiveTabsAfterRemove) {
                activeTabAfterRemove = null;
            }

            const updatedIndicators = state.indicators.delete(needRemoveTab.key)

            saveTabs(listAfterRemoveTab, getStorageTabsKey());
            saveIndicators(updatedIndicators);

            return state.merge({
                tabs: listAfterRemoveTab,
                activeTab: activeTabAfterRemove,
                stockCharts: state.stockCharts.delete(needRemoveTab.key),
                chartDatas: state.chartDatas.delete(needRemoveTab.key),
                indicators: updatedIndicators,
            });

        case actionTypes.REMOVE_NON_FAVORITE_TABS:
            const tickersList = store.getState().trade.tickers.list;
            let tabs = new List();
            let stockCharts = state.stockCharts;
            let chartDatas = state.chartDatas;
            let filteredIndicators = state.indicators;

            state.tabs.forEach((tab) => {
                if (tickersList.find((ticker) => ticker.SymbolId === tab.symbolId).isMark) {
                    tabs = tabs.push(tab);
                } else {
                    stockCharts = stockCharts.delete(tab.key);
                    chartDatas = chartDatas.delete(tab.key);
                    filteredIndicators = filteredIndicators.delete(tab.key);
                }
            });

            saveTabs(tabs, getStorageTabsKey());
            saveIndicators(filteredIndicators);

            return state.merge({
                tabs,
                activeTab: null,
                stockCharts,
                chartDatas,
                indicators: filteredIndicators,
            });

        case actionTypes.CHANGE_POSITIONS:
            const { fromTab, toTab } = action.payload;
            let changePositionsTabs = state.tabs;
            const fromKey = state.tabs.findKey((tab) => {
                return tab === fromTab;
            });
            const toKey = state.tabs.findKey((tab) => {
                return tab === toTab;
            });

            if (fromKey !== undefined && toKey !== undefined) {
                changePositionsTabs = state.tabs.withMutations((tabs) => {
                    return tabs.set(fromKey, toTab).set(toKey, fromTab);
                });
            }

            saveTabs(changePositionsTabs, getStorageTabsKey());

            return state.merge({
                tabs: changePositionsTabs
            });

        case actionTypes.UPDATE_TABS:
            const updatedTabs = new List(action.payload);
            const activeTab = state.activeTab ? updatedTabs.find((tab) => tab._key === state.activeTab._key) : updatedTabs.first() || null;
            const indicators = new Map(updatedTabs.toMap()).mapEntries(([key, tab]) => [tab.key, state.indicators.get(tab.key) || new Map()])

            saveTabs(updatedTabs, getStorageTabsKey());
            saveIndicators(indicators);

            return state.merge({
                activeTab,
                tabs: updatedTabs,
                stockCharts: new Map(updatedTabs.toMap()).mapEntries(([key, tab]) => [tab.key, state.stockCharts.get(tab.key) || new window.Map()]),
                chartDatas: new Map(updatedTabs.toMap()).mapEntries(([key, tab]) => [tab.key, state.chartDatas.get(tab.key) || null]),
                indicators,
            });

        case actionTypes.REMOVE_SHOWN_TABS:
            const listTabsAfterRemovedShown = state.tabs.filter(tab => (!tab.isShown || (state.activeTab.key === tab.key)));
            const hasActiveTabAfterRemovedShown = listTabsAfterRemovedShown.some(tab => state.activeTab && state.activeTab.key === tab.key);

            saveTabs(listTabsAfterRemovedShown, getStorageTabsKey());

            return state.merge({
                tabs: listTabsAfterRemovedShown,
                activeTab: hasActiveTabAfterRemovedShown ? state.activeTab : null
            });

        case actionTypes.SET_STOCK_CHARTS: {
            const { chartKey, stockCharts } = action.payload;

            return state.merge({
                stockCharts: state.stockCharts.set(chartKey, stockCharts),
            });
        }

        case actionTypes.CHART_DATA_UPDATE: {
            const { chartKey, data } = action.payload;

            return state.merge({
                chartDatas: state.chartDatas.set(chartKey, data),
            });
        }

        case actionTypes.INDICATOR_ADD:
        case actionTypes.INDICATOR_UPDATE: {
            const { chartKey, key, indicator } = action.payload;
            const chartIndicators = state.indicators.get(chartKey);
            const indicators = state.indicators.set(chartKey, chartIndicators.set(key, indicator));

            saveIndicators(indicators);

            return state.merge({
                indicators,
            });
        }

        case actionTypes.INDICATOR_REMOVE: {
            const { chartKey, key } = action.payload;
            const chartIndicators = state.indicators.get(chartKey);
            const indicators = state.indicators.set(chartKey, chartIndicators.remove(key));

            saveIndicators(indicators);

            return state.merge({
                indicators,
            });
        }

        case actionTypes.INDICATOR_REPLACE: {
            const { chartKey, key, removeKey, indicator } = action.payload;
            const chartIndicators = state.indicators.get(chartKey);
            const indicators = state.indicators.set(chartKey, chartIndicators.remove(removeKey).set(key, indicator));

            saveIndicators(indicators);

            return state.merge({
                indicators,
            });
        }

        case actionTypes.ALL_INDICATORS_REPLACE: {
            const { chartKey, indicators } = action.payload;
            const newIndicators = state.indicators.set(chartKey, new Map(indicators));

            saveIndicators(newIndicators);

            return state.merge({
                indicators: newIndicators,
            });
        }

        case actionTypes.INDICATOR_TPL_ADD:
        case actionTypes.INDICATOR_TPL_UPDATE: {
            const { key, indicatorTemplate } = action.payload;
            const indicatorTemplates = new window.Map(state.indicatorTemplates).set(key, indicatorTemplate);

            if (!trackingActions.isTrackMasterMode()) {
                Register.set(Types.INDICATOR_TEMPLATES_STORE_NAME, indicatorTemplates);
            }

            return state.merge({
                indicatorTemplates,
            });
        }

        case actionTypes.INDICATOR_TPL_REMOVE: {
            const indicatorTemplates = new window.Map(state.indicatorTemplates);
            indicatorTemplates.delete(action.payload);

            if (!trackingActions.isTrackMasterMode()) {
                Register.set(Types.INDICATOR_TEMPLATES_STORE_NAME, indicatorTemplates);
            }

            return state.merge({
                indicatorTemplates,
            });
        }

        case accountsActions.CHANGED_CURRENT_ACCOUNT:
            const showTickerList = store.getState().trade.tickers.listToShow;
            const filteredTabs = state.tabs.map(tab => {
                const ticker = showTickerList.get(tab.symbolId);
                tab._isShown = !!ticker
                if (tab._isShown) {
                    tab.updateIsShownFromAccount(action.payload);
                }

                return tab;
            });

            return state.merge({
                //activeTab: filteredTabs.find(tab => tab.isShown) || null,
                tabs: filteredTabs,
                isReadyTabs: true,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.TOGGLE_MAGNET_MODE:
            const newState = {...state.chartSettings};
            newState.magnetMode = action.payload;

            return state.set('chartSettings', newState);

        case actionTypes.ACTIVE_ELEMENT_BY_CLICK:
            const { activeChartElementByClick, isChartElementHover, chartActiveElementId } = state;
            return state.merge({
                activeChartElementByClick: (action.payload.activeChartElementByClick !== undefined) ? action.payload.activeChartElementByClick : activeChartElementByClick,
                isChartElementHover: (action.payload.isChartElementHover !== undefined) ? action.payload.isChartElementHover : isChartElementHover,
                chartActiveElementId: (action.payload.chartActiveElementId !== undefined) ? action.payload.chartActiveElementId : chartActiveElementId
            });

        case userActions.LOG_OUT:
            return initialState.merge({
                tmxDataFeed: createTmxDataFeed()
            });

        default:
            return state;
    }
}
