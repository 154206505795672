import React from 'react';
import PropTypes from 'prop-types';

class OutsideClickClose extends React.Component {
    isClickInside = false;

    componentDidMount() {
        document.addEventListener('click', this.onHandleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onHandleDocumentClick);
    }

    onHandleDocumentClick = (e) => {
        const isUserClick = e.pointerId !== -1 || e.pointerType || !!e.sourceCapabilities;

        if (this.isClickInside || !isUserClick) {
            this.isClickInside = false;
            return;
        }
        
        this.props.enable && this.props.onClose();
    }

    handleClickCapture = () => {
        this.isClickInside = true;
    }

    render() {
        return(
            <div
                className="OutsideClickClose"
                onClickCapture={this.handleClickCapture}
            >
                {this.props.children}
            </div>
        );
    }
}

OutsideClickClose.defaultProps = {
    onClose: f => f,
    enable: true
};

OutsideClickClose.propTypes = {
    onClose: PropTypes.func.isRequired,
    enable: PropTypes.bool
};

export default OutsideClickClose;